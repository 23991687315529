<template>
  <div class="page">
    <PageLoading :onFetch="onFetch" />

    <div class="page-container">
      <NavBar />

      <div class="content">
        <!-- 上传图片 -->
        <UploadImage
          :id="uploadImageId"
          style="margin-top: -0.58333rem"
          :shake.sync="uploadBtnShake"
          @change="handleImageChange"
          @login="showLoginBox = true"
        />

        <!-- 选择风格 -->
        <div class="group" id="group-style-id">
          <div class="title">
            选择喜欢的风格（必选）
          </div>
          <div class="scroll-content">
            <div class="style-container">
              <div v-for="row in styles" :key="row.id" class="style-cards">
                <StyleCard
                  v-for="item in row"
                  :key="item.id"
                  :item="item"
                  :checked="item.id === form.style"
                  @click.native="() => handleChange(item)"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 选择空间 -->
        <div class="group" id="group-space-id">
          <div class="title">
            选择空间（必选）
          </div>
          <div class="content">
            <div class="tags">
              <Tag
                v-for="item in options.space"
                :key="item.id"
                :tag="item"
                :checked="item.id === form.space"
                @click.native="handleChange(item)"
              />
            </div>
          </div>
        </div>

        <!-- 选择更多 -->
        <div class="group">
          <div class="title">
            选择更多
          </div>
          <div class="content">
            <div class="more-tags" @click="handleSelectMore">
              <div class="text text-overflow" :class="{ placeholder: !selectMoreStore.data.length }">
                {{ moreTagsText }}
              </div>
              <img src="@/assets/icon-ai-gray-right-arrow.png" class="icon" />
            </div>
          </div>
        </div>
        <Support class="support" />
      </div>

      <div class="btn-box">
        <button class="primary-btn btn-item" :disabled="submitBtnDisabled" @click="handleSubmit">
          AI一键设计我的家
        </button>
        <div class="shake-btn" v-if="!originFile || !form.style || !form.space" @click="handleUploadBtnShake"></div>
      </div>

      <TabBar @login="showLoginBox = true" />
    </div>

    <LoginBoxPopup :show="showLoginBox" @update:show="showLoginBox = $event" :callback-url="callback" />
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import TabBar from '@/components/tabbar.vue';
  import { requestAi, uploadFile, toFormData } from '@/utils';
  import { options, selectMoreStore, authStore, exampleList, businessInfo } from '@/store';
  import UploadImage from '../components/upload-image.vue';
  import StyleCard from '../components/style-card.vue';
  import Tag from '../components/tag.vue';
  import PageLoading from '@/components/page-loading.vue';
  import NavBar from '../components/navbar.vue';
  import LoginBoxPopup from './components/login-box-popup.vue';
  import { omit } from 'lodash';
  import { callbackUrl, ORIGIN_SRC } from '@/constants/index';
  import Support from '@/components/support.vue';

  @Component({
    name: 'AiHome',
    components: {
      TabBar,
      UploadImage,
      StyleCard,
      Tag,
      PageLoading,
      NavBar,
      LoginBoxPopup,
      Support,
    }
  })
  export default class AiHome extends Vue {
    uploadBtnShake = false
    showLoginBox = false

    options = options
    selectMoreStore = selectMoreStore

    originFile = null
    form = {
      origin: '',
      style: '',
      space: '',
      color: '',
      light: '',
      material: '',
      custom: [],
    }

    uploadImageId = 'upload-image-id'
    callback = ''

    get moreTagsText() {
      if (this.selectMoreStore.data.length) {
        return this.selectMoreStore.data.map(item => item.name).join('、');
      }
      return '颜色、材质、或其它';
    }
    handleSelectMore() {
      if (!authStore.isLogin) {
        const { origin, style, space } = this.form;
        this.callback = callbackUrl + this.$router.resolve({ name: 'aiHome', query: { origin, style, space } }).href;
        this.showLoginBox = true;
        return;
      }
      this.$router.push({ name: 'aiSelectMore' });
    }
    @Watch('selectMoreStore.data', { immediate: true })
    onSelectMoreStoreDataChange() {
      selectMoreStore.data.forEach(item => {
        switch (item.type) {
          case 'Options::Material':
            this.form.material = item.id;
            break;
          case 'Options::Light':
            this.form.light = item.id;
            break;
          case 'Options::Color':
            this.form.color = item.id;
            break;
          case 'Options::Custom':
            this.form.custom.push(item.id);
            break;
        }
      });
    }

    async onFetch() {
      await Promise.all([
        businessInfo.tryFetchData(),
        options.tryFetchData(),
        exampleList.tryFetchData(),
      ]);
    }

    async mounted() {
      if (!businessInfo.businessUserId) {
        const errMsg = '请携带有效的商家ID';
        this.$showToast(errMsg);
        return Promise.reject(new Error(errMsg));
      }

      const { code, origin, style, space } = this.$route.query;
      if (code) {
        await authStore.login();
        this.$router.replace({ query: omit(this.$route.query, 'code') });
        if (!authStore.data.phone) {
          this.$router.push({ name: 'aiLogin' });
        } else {
          this.$showToast('登录成功');
        }
      }
      if (origin || style || space) {
        this.form.origin = origin;
        this.form.style = style;
        this.form.space = space;
        this.$router.replace({ query: omit(this.$route.query, 'origin', 'style', 'space') });
      }
      this.wxShare();

      const originSrc = JSON.parse(sessionStorage.getItem(ORIGIN_SRC) || null);
      this.originFile = originSrc;
      if (originSrc?.base64) {
        const formData = originSrc ? toFormData(originSrc.base64) : null;
        this.originFile = formData?.get('file') || null;
      }
    }

    handleImageChange(file) {
      this.originFile = file;
    }

    get styles() {
      if (!options.style || !options.style.length) {
        return [];
      }
      const n = Math.ceil(options.style.length / 3);
      return [
        options.style.slice(0, n),
        options.style.slice(n, 2 * n),
        options.style.slice(2 * n),
      ];
    }

    handleChange(item) {
      switch (item.type) {
        case 'Options::Style':
          this.form.style = item.id;
          break;
        case 'Options::Space':
          this.form.space = item.id;
          break;
        case 'Options::Material':
          this.form.material = this.form.material === item.id ? '' : item.id;
          break;
        case 'Options::Light':
          this.form.light = this.form.light === item.id ? '' : item.id;
          break;
        case 'Options::Color':
          this.form.color = this.form.color === item.id ? '' : item.id;
          break;
        case 'Options::Custom':
          this.form.custom = this.form.custom.includes(item.id)
            ? this.form.custom.filter(id => id !== item.id)
            : [...this.form.custom, item.id];
          break;
      }
    }

    get submitBtnDisabled() {
      const { style, space } = this.form;
      return !this.originFile || !style || !space;
    }

    @Vue.autoLoading
    async handleSubmit() {
      if (!authStore.isLogin) { // 检查登录情况
        const { origin, style, space } = this.form;
        this.callback = callbackUrl + this.$router.resolve({ name: 'aiHome', query: { origin, style, space } }).href;
        this.showLoginBox = true;
        return;
      }

      if (this.originFile) { // 上传图片
        if (this.originFile.signed_id) {
          this.form.origin = this.originFile.signed_id;
        } else {
          try {
            const res = await uploadFile(this.originFile);
            this.form.origin = res.signed_id;
          } catch (e) {
            let errMsg = '上传失败，请重试';
            if (e.status === 401) {
              errMsg = '登录已过期，请重新登录';
              this.showLoginBox = true;
            }
            this.$showToast(errMsg);
            Promise.reject(new Error(e));
          }
        }
      }

      const { data: { code } } = await requestAi.post('images', {
        origin: this.form.origin,
        raw: this.form.origin,
        option_ids: [
          this.form.style,
          this.form.space,
          this.form.material,
          this.form.light,
          this.form.color,
          ...this.form.custom,
        ].filter(Boolean),
      });
      this.$router.push({ name: 'aiResultDetail', query: { code, isCreate: 1 } });
    }
    async handleUploadBtnShake() {
      let element = document.querySelector(`#${this.uploadImageId}`);
      if (!this.originFile) {
        this.uploadBtnShake = true;
      } else if (!this.form.style) {
        element = document.querySelector('#group-style-id');
        this.$showToast('请选择喜欢的风格');
      } else if (!this.form.space) {
        element = document.querySelector('#group-space-id');
        this.$showToast('请选择空间');
      }
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px 13px 14px;
    overflow: auto;
    gap: 24px;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    .scroll-content {
      position: relative;

      &::after {
        position: absolute;
        z-index: 1;
        top: 2px;
        bottom: 2px;
        right: 0px;
        width: 20px;
        background: linear-gradient(271deg, #111111 0%, rgba(255, 255, 255, 0) 100%, rgba(17, 17, 17, 0) 100%);
        opacity: 0.5;
        content: '';
      }
    }

    .style-container {
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      gap: 6px;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }

      .style-cards {
        display: flex;
        gap: 6px;
      }
    }

    .tags {
      display: grid;
      grid-template-columns: repeat(var(--columns, 4), 1fr);
      gap: 8px;
    }

    .more-tags {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: 36px;
      padding: 10px 14px;
      border-radius: 8px;
      background-color: #f7f7f7;

      .text {
        flex: 1;

        &.placeholder {
          color: #828282;
        }
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }

  .support {
    margin-top: 12px;
  }
}

.btn-box {
  position: relative;
  padding: 8px 13px;

  .btn-item {
    height: 50px;
  }

  .shake-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
