import { requestApp } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { businessInfo } from './business';
import { authStore } from './auth-store';
import { privateDomainAction } from './private-domain-action';
import { DOMAIN_ACTION } from '@/constants/index';

class SubmitContactStore extends SimpleStore {
  async fetchData() {
    const res = await requestApp.post('/businessCustomer/submitContact', { businessUserId: businessInfo.businessUserId, userId: authStore.data.userId });
    // 更新当前用户在门店提交的信息
    businessInfo.fetchData();
    this.mergeFetched(res.data);
    await privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.GET_CONTACT_INFORMATION }); // 触发 提交联系方式 埋点
  }
}

export const submitContact = new SubmitContactStore();
