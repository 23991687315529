<template>
  <div class="share-poster-page">
    <NavBar title="生成海报" />
    <div class="page" :style="{ 'min-height': getContainerHeight() }">
      <PageLoading :onFetch="onFetch" />
      <div class="poster-box">
        <img
          :src="resultImg"
          class="result-img"
          :style="{
            width: `${$refs.posterRef?.clientWidth}px`,
            height: `${$refs.posterRef?.clientHeight}px`,
          }"
          crossorigin="anonymous"
        />
      </div>

      <div class="bottom-box">
        <div class="tips">长按图片直接分享给好友</div>
        <button class="primary-btn" @click="$showToast('请长按图片保存')">保存图片</button>
      </div>

      <div ref="posterRef" class="poster" v-show="!resultImg">
        <div class="title">
          {{ get(detail,'title') }}
        </div>
        <div class="images">
          <img v-for="image in detail.results" :key="image.id" class="image" :src="image.url" :style="{ height: imageH }" crossorigin="anonymous" />
        </div>
        <div class="share-info">
          <div class="left-box">
            <img class="avatar" :src="get(detail, 'user.avatar.url') || require('@/assets/default-avatar.png')" crossorigin="anonymous" />
            <div class="flex-col share-text">
              <div class="nickname">
                <span class="text-overflow">{{ get(detail, 'user.screen_name') || '微信用户' }}</span>
              </div>
              <div class="text">我几秒设计了这个作品</div>
              <div class="text">你也来试试！</div>
            </div>
          </div>
          <div class="qrcode-wrapper">
            <canvas ref="qrcode" class="qrcode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import NavBar from '../components/navbar.vue';
  import { getBrowserInterfaceSize, requestAi } from '@/utils';
  import { businessInfo } from '@/store';
  import { get } from 'lodash';
  import PageLoading from '@/components/page-loading.vue';
  import qrcode from 'qrcode';
  import html2canvas from 'html2canvas';

  @Component({
    components: {
      NavBar,
      PageLoading,
    },
  })
  export default class SharePoster extends Vue {
    posterImg = ''
    qrcode = ''
    imageH = 0
    detail = {
      results: []
    }
    resultImg = ''

    get = get

    get code() {
      return this.$route.query.code;
    }

    get url() {
      const query = {
      code: this.code,
      isShare: 1,
      businessId: businessInfo.businessUserId,
      title: businessInfo.businessUserTitle,
      staffId: businessInfo.businessUserEmployeeId,
      storeQRCode: businessInfo.storeQRCodeFlag
    };
      const href = this.$router.resolve({ name: 'aiResultDetail', query }).href;
      return `${location.origin}${href}`;
    }

    async onFetch() {
      const designImageWidth = 158;
      const ele = this.$refs.qrcode;
      const { data } = await requestAi.get(`images/${this.code}`);
      qrcode.toCanvas(ele, this.url, {
        width: 200,
        height: 200,
      });
      this.detail = data;
      const { width = 0, height = 0 } = get(this.detail, 'raw.metadata', {});
      this.imageH = designImageWidth / width * height;
      if (!this.$refs.posterRef) {
        return;
      }
      await this.$nextTick();
      const img = await this.getImage(this.$refs.posterRef);
      this.resultImg = img.src;
    }

    getContainerHeight() {
      const { pageHeight } = getBrowserInterfaceSize();
      const sab = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--sab'));
      return `${pageHeight - sab - 56}px`;
    }

    getImage(html) {
      return new Promise((resolve, reject) => {
        html2canvas(html, {
          allowTaint: true,
          useCORS: true,
        }).then(function(canvas) {
          const url = canvas.toDataURL('image/jpeg');
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.src = url;
          resolve(image);
        }).catch(reject);
      });
    }
  }
</script>

<style lang="scss" scoped>
.share-poster-page {
  height: 100vh;
  overflow: scroll;
}

.page {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  padding: 14px 12px;
  color: #fff;
  background: #000;
}

.qrcode-wrapper {
  position: relative;
  width: 100px;
  height: 100px;

  .qrcode {
    position: absolute;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

.poster-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 18px 0;

  .result-img {
    border-radius: 8px;
    overflow: hidden;
  }
}

.bottom-box {
  margin-top: auto;
  font-weight: 500;
  font-size: 14px;
  text-align: center;

  .tips {
    padding-bottom: 16px;
  }

  .primary-btn {
    height: 36px;
  }
}

.poster {
  box-sizing: border-box;
  padding: 11px 14px 9px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
    color: #1e1e1e;
  }

  .images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 11px;
    border-radius: 10px;
    overflow: hidden;
    gap: 7px;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .share-info {
    display: flex;
    margin-top: 9px;
    color: #000;

    .left-box {
      display: flex;
      flex: 1;
      min-width: 0;
      padding-top: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1e1e1e;
      gap: 6px;

      .avatar {
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }

      .share-text {
        flex: 1;
        min-width: 0;
        margin-top: 3px;

        .nickname {
          margin-bottom: 12px;
        }

        .text + .text {
          margin-top: 3px;
        }
      }
    }
  }
}

</style>
