<template>
  <div class="cmpt-ideas">
    <div class="second-tabs">
      <div v-for="tab in tabs" :key="tab.key" class="tabs" :class="{ active: modelValue === tab.key }"
        @click="handleTabChange(tab.key)">
        {{ tab.label }}
      </div>
    </div>

    <div v-if="albums.data.length" class="album-list" ref="albumList" @scroll="handleAlbumsScroll" @touchstart.stop @touchmove.stop @touchend.stop>
      <div v-for="(item,index) in albums.data" :key="index" class="item" @click="$router.push({name: 'albums', params: { id: item.id }})">
        <img src="@/assets/folder.svg" alt="" class="icon" />
        <span class="text">{{ item.albumName }}</span>
      </div>
    </div>

    <div class="content" ref="contentRef" @scroll="handleScroll">
      <InfiniteList :id="id" :store="store" @more="waterfallUpdate" class="flex-1">
        <Waterfall ref="waterfallRef" :options="{ gap: '0.5rem', columns: 2 }">
          <WorkCard @imgLoad="waterfallUpdate" v-for="(item, index) in store.data" :key="index" :card="item" class="item" />
        </Waterfall>
      </InfiniteList>

      <Support class="support" />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
import WorkCard from '@/components/work-card.vue';
import InfiniteList from '@/components/infinite-list.vue';
import Waterfall from 'easy-waterfall-2/dist/waterfallVue';
import { throttle } from 'lodash';
import Support from '@/components/support.vue';
import { albums } from '@/store';

@Component({
  components: { WorkCard, InfiniteList, Waterfall, Support },
})
export default class Ideas extends Vue {
  @Prop({ type: Object, required: true, default: () => ({}) }) store;
  @Prop({ type: Array, default: () => ([]) }) tabs;
  @Model('update:modelValue', { type: Number, default: 0 }) modelValue;

  albums = albums

  id = 'workCardListId';
  contentScrollTop = 0
  albumsListScrollLeft = 0

  handleTabChange(key) {
    this.$emit('update:modelValue', key);
  }

  handleScroll() {
    this.contentScrollTop = this.$refs.contentRef?.scrollTop;
    this.$emit('scroll', this.contentScrollTop);
  }

  handleAlbumsScroll() {
    this.albumsListScrollLeft = this.$refs.albumList?.scrollLeft;
  }

  async activated() {
    await this.$nextTick();
    this.$refs.contentRef.scrollTop = this.contentScrollTop;
    this.$refs.albumList.scrollLeft = this.albumsListScrollLeft;
  }

  async mounted() {
    this.waterfallUpdate();
    albums.tryFetchData();
  }

  waterfallUpdate = throttle(() => {
    if (this.$refs.waterfallRef) {
      this.$refs.waterfallRef.update();
    }
  }, 100);

  @Watch('store.data.length')
  async dataUpdate() {
    await this.$nextTick();
    this.waterfallUpdate();
  }
}
</script>

<style lang="scss" scoped>
.cmpt-ideas {
  display: flex;
  flex-direction: column;
  height: 100%;

  .second-tabs {
    display: flex;
    justify-content: center;
    padding-top: 2px;
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.28px;
    color: #8c8c8c;
    gap: 36px;

    .tabs {
      white-space: nowrap;

      &.active {
        font-weight: 500;
        color: $primary-color;
      }
    }
  }

  .album-list {
    display: flex;
    padding: 12px 7px;
    overflow-x: scroll;
    gap: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding: 4px 9px;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.10);
      gap: 12px;
      cursor: pointer;

      .icon {
        width: 14px;
        height: 14px;
      }

      .text {
        font-weight: 350;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        letter-spacing: 0.28px;
        color: #1e1e1e;
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    height: 0;
    padding: 8px 6px;
    padding-bottom: 92px;
    overflow-y: scroll;
  }

  .support {
    margin: 25px 0 92px;
  }
}
</style>
