<template>
  <ActionSheet :value="show" max-height="100vh" @close="$emit('update:show', false)">
    <div class="employee-list">
      <div v-for="(info, index) in contactInfo" :key="index" class="item" @click="$emit('custom-click', info)">
        <div class="info-box">
          <div class="avatar">
            <img :src="getSrc({ src: info?.headImage })" alt="" class="image" />
          </div>
          <div class="info">
            <div class="name">
              {{ info.contactName }}
            </div>
            <div class="position">
              {{ info.position }}
            </div>
          </div>
        </div>
        <div class="contact-box">
          <div class="btn">
            <img src="@/assets/icon-phone.png" alt="" class="icon" />
            <span>联系</span>
          </div>
        </div>
      </div>
      <div class="reset-btn btn-cancel" @click="$emit('update:show', false)">
        取消
      </div>
    </div>
  </ActionSheet>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ActionSheet from '@/components/common/action-sheet.vue';
import { copy, getSrc } from '@/utils';

@Component({
  components: { ActionSheet }
})
export default class ContactInfoListPopup extends Vue {
  @Prop({ type: Boolean, default: false }) show
  @Prop({ type: Array, default: () => [] }) contactInfo

  copy = copy
  getSrc = getSrc
}
</script>

<style scoped lang="scss">
  .employee-list {
    flex: 1;
    padding: 0 16px;
    overflow: scroll;
    font-size: 14px;
    line-height: 18px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 19px 0;
      border-bottom: 1px solid #f0f0f0;

      .info-box {
        display: flex;
        gap: 11px;

        .avatar {
          width: 49px;
          height: 49px;
          border-radius: 50%;
          overflow: hidden;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 9px;

          .name {
            font-weight: 500;
          }

          .position {
            font-size: 12px;
            line-height: 16px;
            color: #8c8c8c;
          }
        }
      }

      .btn {
        display: flex;
        align-items: center;
        padding: 5px 16px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        color: $primary-color;
        background-color: #f5b545;
        gap: 10px;

        .icon {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .btn-cancel {
    padding: 20px 0;
    font-weight: 350;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.32px;
  }
</style>
