<template>
  <div class="page">
    <div class="login-page">
      <div class="box compare-wrapper">
        <img src="https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/init/gif/ai-login.gif" alt="" class="compare-image" />
      </div>
      <div class="box info">
        <div class="title">
          <span class="strong">10秒</span>AI免费设计新家
        </div>

        <div class="form-box">
          <div class="field">
            <div class="label">
              手机号
            </div>
            <input v-model="localPhone" class="reset-input input" v-enter-blur />
          </div>

          <div class="field">
            <div class="label">
              验证码
            </div>
            <input v-model="verifyCode" placeholder="" class="reset-input input flex-1" @input="verifyCodeChange" v-enter-blur />
            <div v-if="countDown > 0" class="reset-btn send-verify">
              {{ `${countDown}s后重新发送` }}
            </div>
            <button v-else class="reset-btn send-verify" @click="onHandleSendVerifyCode">
              发送验证码
            </button>
          </div>
        </div>

        <div class="click-box">
          <div class="checkbox-label" @click="checked = !checked">
            <Checkbox :model-value="checked" />
            <span class="checkbox-text">
              我已阅读并同意<strong
                @click="$router.push({ name: 'agreement', query: { agreementName: '用户协议' } })">《用户协议》</strong>和<strong
                @click="$router.push({ name: 'agreement', query: { agreementName: '隐私协议' } })">《隐私政策》</strong>
            </span>
          </div>
          <button class="reset-btn submit-btn" @click="onSubmit">
            立即登录
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Checkbox from '@/components/checkbox.vue';
import { requestUser, sleep } from '@/utils';
import { authStore } from '@/store';
import PageLoading from '@/components/page-loading.vue';

@Component({
  components: { Checkbox, PageLoading }
})
export default class AiLogin extends Vue {
  localPhone = ''
  verifyCode = ''
  countDown = ''
  checked = true
  timer = null

  mounted() {
    this.wxShare();
  }

  verifyCodeChange(e) {
    const { value } = e.target;
    if (value.length > 4) {
      this.verifyCode = value.slice(0, 4);
    }
  }

  async onHandleSendVerifyCode() {
    if (!this.localPhone) {
      this.$showToast('请输入手机号');
      return;
    }

    const regex = /^1[3456789]\d{9}$/;
    if (!regex.test(this.localPhone)) {
      this.$showToast('号码格式有误');
      return;
    }

    try {
      await requestUser.get(`/business/getCode?type=h5&phone=${this.localPhone}`);
      this.$showToast('发送成功');
      this.timer && clearInterval(this.timer);
      this.countDown = 60;
      this.timer = setInterval(() => {
        if (this.countDown <= 0) {
          clearInterval(this.timer);
          return;
        }
        this.countDown -= 1;
      }, 1000);
    } catch (e) {
      this.$showToast(e.message);
    }
  }

  async validateForm() {
    const errors = [];
    if (!this.localPhone) {
      errors.push('请填写手机号');
    }
    if (!this.verifyCode) {
      errors.push('请填写验证码');
    }
    if (!this.checked) {
      errors.push('请先同意《用户协议》和《隐私政策》');
    }
    if (errors.length === 0) {
      return Promise.resolve();
    }
    const errMsg = errors[0];
    return await Promise.reject(new Error(errMsg));
  }

  // 绑定联系方式
  async onSubmit() {
    try {
      await this.validateForm();
      await requestUser.post('/h5/checkSmsCode', { phone: this.localPhone, code: this.verifyCode, userId: authStore.data.userId });
      await authStore.fetchData();
      this.$showToast('登录成功');
      await sleep(3000);
      this.$router.push({ name: this.$route.query.name || 'aiHome' });
    } catch (err) {
      this.$showToast(err.message);
      throw err;
    }
  }

  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  }
}
</script>

<style lang="scss" scoped>
.page {
  box-sizing: border-box;

  @include paddingBottomSafeArea();
  background-color: #fafafa;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 16px;

  .box {
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #fff;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .compare-wrapper {
    flex: 1;
    width: 100%;
    height: 0;

    .compare-image {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    padding: 20px 12px 12px;
    gap: 25px;

    .title {
      font-size: 26px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 2.08px;
      color: #1e1e1e;

      .strong {
        font-weight: 500;
        color: #ea250d;
      }
    }

    .form-box {
      display: flex;
      flex-direction: column;

      .field {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 60px;
        border-bottom: 1px solid #f0f0f0;

        .label {
          width: 58px;
          padding-left: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          white-space: nowrap;
          letter-spacing: 0.32px;
          color: #8c8c8c;
        }

        .avatar-btn {
          box-sizing: border-box;
          width: 44px;
          height: 44px;
          margin-left: 11px;
          border: 1px solid #fff;
          border-radius: 50%;
          overflow: hidden;

          .image {
            width: 100%;
            height: 100%;
          }
        }

        .icon-arrow {
          box-sizing: border-box;
          width: 6px;
          height: 6px;
          margin: 10px;
          border: 1px solid #8c8c8c;
          border-bottom: none;
          border-left: none;
          transform: rotate(45deg);
        }

        .input {
          flex: 1;
          min-width: 80px;
          height: 35px;
          margin-left: 11px;
          font-weight: 400;
          font-size: 16px;
          line-height: 35px;
          text-align: left;
          letter-spacing: 0.32px;
          color: $primary-color;
        }

        .send-verify {
          padding: 0 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          white-space: nowrap;
          letter-spacing: 0.56px;
          color: #4c4c4c;
        }
      }
    }

    .click-box {
      display: flex;
      flex-direction: column;
      gap: 11px;

      .submit-btn {
        padding: 16px;
        border-radius: 50px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        white-space: nowrap;
        letter-spacing: 0.72px;
        color: #fff;
        background: #4c4c4c;
      }

      .checkbox-label {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #8c8c8c;
        gap: 6px;

        strong {
          color: #000;
        }
      }
    }
  }
}
</style>
