<template>
  <div class="cmpt-share-tips">
    <van-popup
      :value="value"
      position="center"
      duration="0"
      :style="{
        width: '100%',
        backgroundColor: '#181818CC',
      }"
      @click-overlay="handleClose"
      @close="handleClose"
    >
      <div class="container">
        <div class="share-top">
          <div class="space"></div>
          <div class="title">点击右上角</div>
          <div class="space">
            <img src="@/assets/icon-share-tips-arrow.png" class="arrow-icon" />
          </div>
        </div>
        <div class="share-bottom">
          <div class="text">分享给好友或者朋友圈</div>
          <button class="reset-btn action-btn" @click="handleClose">
            我知道了
          </button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Vue, Component, Model } from 'vue-property-decorator';

  @Component
  export default class ShareTipsPopup extends Vue {
    @Model('input', { type: Boolean, default: false }) value;

    handleClose() {
      this.$emit('input', false);
    }
  }
</script>

<style lang="scss" scoped>
.cmpt-share-tips {
  :deep(.van-popup) {
    max-width: 100vw;
  }

  :deep(.van-overlay) {
    left: 50%;
    max-width: $h5-max-width;
    transform: translateX(-50%);
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: auto;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #fff;

  .space {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 17px 6px 10px;

    .arrow-icon {
      width: 100%;
      max-height: 60vh;
    }
  }

  .text {
    white-space: nowrap;
  }

  .share-top {
    display: flex;
    align-items: flex-end;
  }

  .action-btn {
    width: 128px;
    margin-top: 81px;
    border: 1px solid #fff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2em;
    background-color: transparent;
  }
}
</style>
