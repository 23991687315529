import { Collection } from './helper/collection';
import { requestAi, autoLoading } from '@/utils';

class ImagesStore extends Collection {
  async fetch(params) {
    return requestAi.get('images', { params });
  }

  fetchData() {
    this.params.page = 1;
    return this.fetching(async () => {
      const { data, meta } = await this.fetch(this.params);
      this.meta = meta;
      const isUnequal = this.data.length !== data.length || this.data.map((item, index) => {
        // 列表的内容不会变的，所以只需要比较 id 和 state 即可。
        return item.id === data[index].id && item.state === data[index].state;
      }).includes(false);
      if (isUnequal) {
        // 如果内容一样就不要重新赋值了，否则会导致列表闪烁。
        this.data = data;
      }
    });
  }

  async deleteRecord(id) {
    const { data } = await autoLoading(requestAi.delete(`images/${id}`));
    this.removeItemById(data.id);
  }
}

export const imagesStore = new ImagesStore({ params: { state_eq: 'completed' } });
