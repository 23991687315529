import { SimpleStore } from './helper/simple-store';
import { ACCESS_TOKEN_KEY } from '@/constants';
import { getUrlCode, getWeChatCode } from '@/utils';
import { requestApp, requestUser } from '../utils/request';

const { Storage } = require('@/utils/storage');

class AuthStore extends SimpleStore {
  constructor(...agrs) {
    super(...agrs);
    this.storage = new Storage(ACCESS_TOKEN_KEY);
  }

  set access_token(v) {
    this.$access_token = v;
    this.storage.set(ACCESS_TOKEN_KEY, v);
  }

  get access_token() {
    this.$access_token = this.storage.get(ACCESS_TOKEN_KEY);
    return this.$access_token;
  }

  get isLogin() {
    return !!this.access_token;
  }

  async checkLogin(callbackUrl) {
    try {
      await this.tryFetchData();
      return this.data;
    } catch {
      getWeChatCode(callbackUrl);
    }
  }

  async fetchData() {
    return this.mergeFetched(requestApp.get('my/myInfo'));
  }

  logout() {
    this.access_token = '';
    this.data = {};
  }

  async login() {
    const code = getUrlCode('code');
    if (!code) {
      getWeChatCode(); // 微信授权
    } else { // 获取token
      const { data: newData } = await requestUser.get(`h5/wx-login/callback?code=${code}`);
      this.access_token = newData.data.token;
      this.data = newData.data;
    }
    return this.data;
  }

  async updateUserInfo(params) {
    await requestApp.post('/user/edit-info', params);
    this.fetchData();
  }
}

export const authStore = new AuthStore();
