import { RESUIT_GROUP } from '@/constants';

class ResultGroup {
  constructor() {
    const group = localStorage.getItem(RESUIT_GROUP);
    try {
      this.group = group ? JSON.parse(group) : {};
    } catch (e) {
      this.group = {};
    }
  }

  group = {};

  addResult(groupId, result) {
    const list = this.group[groupId] || [];
    const index = list.findIndex(item => item.id === result.id);
    if (index === -1) {
      // 如果已经存在了，就不要保存
      list.push(result);
    }
    this.group[groupId] = list;
    localStorage.setItem(RESUIT_GROUP, JSON.stringify(this.group));
    return list;
  }

  getResults(groupId) {
    return this.group[groupId] || [];
  }
}

export const resultGroup = new ResultGroup();
