import { requestApp } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { privateDomainAction, collectionsStore } from '@/store';
import { DOMAIN_ACTION } from '@/constants/index';

class DetailStore extends SimpleStore {
  async fetchData(params) {
    this.data = {};
    const res = await requestApp.get(`/haokan/trend-detail/${params.trendIssueId}`);
    this.mergeFetched(res.data);
  }

  async toggleCollection(params = {}) {
    if (this.data.privateDomainCollect) { // 取消收藏
      await collectionsStore.cancelCollect(params.targetId);
    } else { // 收藏
      await privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.FAVORITE, ...params }); // 收藏
    }
  }

  updateCollectionStatus() {
    this.data.privateDomainCollect = !this.data.privateDomainCollect;
  }
}

export const detailStore = new DetailStore();
