export function copy(content) {
  const { text, successWriter = '复制成功', failWriter = '复制失败\n请稍后再试' } = content || {};
   if (content) {
    this.$copyText(text || content).then(() => {
      this.$showToast(successWriter);
    }).catch(() => {
      this.$showToast(failWriter);
    });
  }
}
