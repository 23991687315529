<template>
  <div class="cmpt-water-mask">
    <div class="img-box">
      <img
        :src="resultImg"
        @load="$emit('finish')"
        crossorigin="anonymous"
      />
    </div>

    <div class="water-mask" ref="waterMaskRef" v-if="!resultImg">
      <img :src="src" alt="" class="image" />
      <div class="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import html2canvas from 'html2canvas';

@Component
export default class Inquiry extends Vue {
  @Prop({ type: String }) src;
  @Prop({ type: String }) text;

  resultImg = '';

  async mounted() {
    this.$emit('init');
    await this.$nextTick();
    const img = await this.getImage(this.$refs.waterMaskRef);
    this.resultImg = img.src;
  }

  get style() {
    return {
      width: this.$refs.waterMaskRef?.clientWidth,
      height: this.$refs.waterMaskRef?.clientHeight,
    };
  }

  getImage(html) {
    return new Promise((resolve, reject) => {
      html2canvas(html, {
        allowTaint: true,
        useCORS: true,
        height: html.clientHeight - 2,
      }).then(function(canvas) {
        const url = canvas.toDataURL('image/jpeg');
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = url;
        resolve(image);
      }).catch(reject);
    });
  }
}
</script>

<style lang="scss" scoped>
.cmpt-water-mask {
  width: 100%;
  height: 100%;

  .water-mask {
    position: relative;
    width: 100%;
    height: 100%;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .text {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 6px 10px;
      margin: 10px;
      border-radius: 14px;
      font-size: 12px;
      color: #fff;
      background: rgba($color: #000000, $alpha: 0.4);
    }
  }

  .img-box > img {
    width: 100%;
    height: 100%;
  }
}
</style>
