import { DOMAIN_OSS } from '@/constants/index';
import defaultAvatar from '@/assets/head.svg';

export function getSrc({ src, suffix, isAvatar = true }) {
  if (src) {
    if (src.startsWith('http')) {
      return src;
    }
    return `${DOMAIN_OSS}${src}${suffix ?? ''}`;
  }
  return isAvatar ? defaultAvatar : '';
}
