import { appid } from '@/constants/index';

// 判断是否是微信内置的浏览器
export function isWXBrowser() {
  return (
    String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i))
        === 'micromessenger'
  );
}

// 截取地址栏code
export function getUrlCode(name) {
  return (new URL(location.href)).searchParams.get(name);
}

// 访问微信地址，用来获取code
export function getWeChatCode(redirectUri) {
  const scope = 'snsapi_userinfo';
  const local = encodeURIComponent(redirectUri || window.location.href); // 获取当前页面地址作为回调地址

  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=${scope}&state=123&connect_redirect=1#wechat_redirect`;
}
