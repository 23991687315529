<script>
import { Vue, Component } from 'vue-property-decorator';
import { options, selectMoreStore } from '@/store';
import Tag from '@/views/ai/components/tag.vue';
import NavBar from '@/views/ai/components/navbar.vue';
import ButtonFixedBottom from '@/components/common/button-fixed-bottom.vue';
import _ from 'lodash';
import LoginBoxPopup from '../home/components/login-box-popup.vue';
import { callbackUrl } from '@/constants/index';
import PageLoading from '@/components/page-loading.vue';
import LinearCloseIcon from '@/assets/icon-ai-linear-close.png';
import DefaultCloseIcon from '@/assets/icon-ai-delete.png';

@Component({
  name: 'SelectMore',
  components: {
    Tag,
    ButtonFixedBottom,
    NavBar,
    LoginBoxPopup,
    PageLoading,
  },
})
export default class SelectMore extends Vue {
  options = options
  form = {
    color: '',
    light: '',
    material: '',
    custom: [],
  }
  showCustomTagPopup = false
  customTag = ''
  showLoginBox = false
  loading = false

  LinearCloseIcon = LinearCloseIcon
  DefaultCloseIcon = DefaultCloseIcon

  get selectedList() {
    const color = _.find(this.options.color, { id: this.form.color });
    const light = _.find(this.options.light, { id: this.form.light });
    const material = _.find(this.options.material, { id: this.form.material });
    const custom = this.options.custom.filter(item => this.form.custom.includes(item.id));
    return [
      color,
      light,
      material,
      ...custom,
    ].filter(Boolean);
  }

  async onFetch() {
    try {
      await options.getCustomOptions();
    } catch (e) {
      let errMsg = '获取数据失败，请重试';
      if (e.status === 401) {
        this.showLoginBox = true;
        errMsg = '登录已过期，请重新登录';
      }
      this.$showToast(errMsg);
    }
  }

  async mounted() {
    selectMoreStore.data.forEach(item => {
      switch (item.type) {
        case 'Options::Material':
          this.form.material = item.id;
          break;
        case 'Options::Light':
          this.form.light = item.id;
          break;
        case 'Options::Color':
          this.form.color = item.id;
          break;
        case 'Options::Custom':
          this.form.custom.push(item.id);
          break;
      }
    });
    await options.tryFetchData();
    this.$refs.fixedBottom.update();
    this.wxShare({ canShare: false });
  }

  get aiHomeHref() {
    return `${callbackUrl}${this.$router.resolve({ name: 'aiHome' }).href}`;
  }

  handleChange(item) {
    switch (item.type) {
      case 'Options::Material':
        this.form.material = this.form.material === item.id ? '' : item.id;
        break;
      case 'Options::Light':
        this.form.light = this.form.light === item.id ? '' : item.id;
        break;
      case 'Options::Color':
        this.form.color = this.form.color === item.id ? '' : item.id;
        break;
      case 'Options::Custom':
        this.form.custom = this.form.custom.includes(item.id)
          ? this.form.custom.filter(id => id !== item.id)
          : [...this.form.custom, item.id];
        break;
    }
    this.$refs.fixedBottom.update();
  }

  goToHome() {
    this.$router.replace({ name: 'aiHome' });
  }

  handleSubmit() {
    selectMoreStore.updateData(this.selectedList);
    this.goToHome();
  }

  async handleAddCustomTag() {
    if (!this.customTag) {
      this.$showToast('请输入描述');
      return;
    }

    this.showCustomTagPopup = false;
    this.loading = true;
    try {
      await options.addCustomOption(this.customTag);
      this.loading = false;
      this.customTag = '';
    } catch (e) {
      this.loading = false;
      this.$showToast('添加失败，请重试');
    }
  }

  async deleteCustomOption(id) {
    try {
      this.loading = true;
      await options.deleteCustomOption(id);
    } catch (e) {
      this.$showToast('删除失败，请重试');
    } finally {
      this.showCustomTagPopup = false;
      this.loading = false;
    }
  }
}
</script>

<template>
  <div class="page">
    <PageLoading :onFetch="onFetch" />
    <div class="page-container">
      <NavBar title="选择更多" />
      <div class="content">
        <!-- 颜色搭配 -->
        <div class="group">
          <div class="title">
            颜色搭配
          </div>
          <div class="content">
            <div class="tags">
              <Tag
                v-for="item in options.color"
                :key="item.id"
                :tag="item"
                :checked="item.id === form.color"
                @click.native="handleChange(item)"
              />
            </div>
          </div>
        </div>
        <!-- 灯光类型 -->
        <div class="group">
          <div class="title">
            灯光类型
          </div>
          <div class="content">
            <div class="tags" style="--columns: 3;">
              <Tag
                v-for="item in options.light"
                :key="item.id"
                :tag="item"
                :checked="item.id === form.light"
                @click.native="handleChange(item)"
              />
            </div>
          </div>
        </div>

        <!-- 主要材质 -->
        <div class="group">
          <div class="title">
            主要材质
          </div>
          <div class="content">
            <div class="tags">
              <Tag
                v-for="item in options.material"
                :key="item.id"
                :tag="item"
                :checked="item.id === form.material"
                @click.native="handleChange(item)"
              />
            </div>
          </div>
        </div>
        <div class="group">
          <div class="title">
            自定义
          </div>
          <div class="content">
            <div class="tags">
              <Tag
                v-for="item in options.custom"
                :key="item.id"
                :tag="item"
                :checked="form.custom.includes(item.id)"
                @click.native="handleChange(item)"
              >
                <img slot="icon" :src="form.custom.includes(item.id) ? LinearCloseIcon : DefaultCloseIcon" class="icon" @click.stop="deleteCustomOption(item.id)" />
              </Tag>
              <Tag :tag="{ name: '自定义' }" @tag-click="showCustomTagPopup = true">
                <img slot="icon" src="@/assets/icon-ai-add.png" class="icon"/>
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loading-box">
      <van-loading size="2rem" vertical />
    </div>

    <ButtonFixedBottom ref="fixedBottom">
      <div class="footer-warapper">
        <div class="label">已选</div>
        <div class="content">
          <div class="list">
            <div
              v-for="item, index in selectedList"
              :key="index"
              class="item"
              @click="handleChange(item)"
            >
              <span class="text">{{ item.name }}</span>
              <img src="@/assets/icon-ai-linear-close.png" class="icon"/>
            </div>
          </div>
          <div v-if="!selectedList.length" class="empty">暂无选择</div>
        </div>
        <div class="btn-group">
          <button class="reset-btn btn cancel-btn" @click="goToHome">取消</button>
          <button class="reset-btn btn primary-btn" @click="handleSubmit">确认选择</button>
        </div>
      </div>
    </ButtonFixedBottom>

    <div class="test">
      <van-popup
      v-model="showCustomTagPopup"
      :style="{
        width: '24.58333rem',
        borderRadius: '0.16667rem'
      }"
      @click-overlay="showCustomTagPopup = false"
      class="popup"
    >
      <div class="custom-tag-popup">
        <img src="@/assets/icon-close-gary.png" class="icon-close" @click="showCustomTagPopup = false" />
        <div class="title">新增描述</div>
        <input v-model="customTag" placeholder="描述" class="reset-input input" v-enter-blur />
        <button class="reset-btn btn primary-btn" @click="handleAddCustomTag">确定</button>
      </div>
    </van-popup>
    </div>

    <LoginBoxPopup :show="showLoginBox" @update:show="showLoginBox = $event" :callback-url="aiHomeHref" />
  </div>
</template>

<style lang="scss" scoped>
.page {
  display: flex;
  width: 100%;

  :deep(.van-overlay) {
    left: 50%;
    max-width: $h5-max-width;
    transform: translateX(-50%);
  }
}

.page-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: scroll;

  > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 14px 13px;
    overflow: auto;
    gap: 24px;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    .scroll-content {
      position: relative;

      &::after {
        position: absolute;
        z-index: 1;
        top: 2px;
        bottom: 2px;
        right: 0px;
        width: 20px;
        background: linear-gradient(271deg, #111111 0%, rgba(255, 255, 255, 0) 100%, rgba(17, 17, 17, 0) 100%);
        opacity: 0.5;
        content: '';
      }
    }

    .style-container {
      display: flex;
      flex-direction: column;
      gap: 6px;
      overflow-x: auto;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }

      .style-cards {
        display: flex;
        gap: 6px;
      }
    }

    .tags {
      display: grid;
      grid-template-columns: repeat(var(--columns, 4), 1fr);
      gap: 8px;
    }
  }
}

.loading-box {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba($color: #f0f0f0, $alpha: 0.3);
}

.btn-box {
  position: relative;
  padding: 8px 13px;

  .btn-item {
    height: 50px;
  }

  .shake-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.footer-warapper {
  box-sizing: border-box;
  padding: 16px 13px;

  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  .content {
    margin: 16px 0 20px;

    .empty {
      display: flex;
      justify-content: center;
      width: 100%;
      font-weight: 400;
      font-size: 13px;
      color: #828282;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 8px 10px 8px 16px;
      border-radius: 8px;
      background: linear-gradient(97.51deg, rgba(229, 0, 14, 0.12) -7.67%, rgba(252, 159, 9, 0.12) 99.65%);

      .text {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: transparent;
        background-image: linear-gradient(96.49deg, #e5000e -44.94%, #fc9f09 105.93%);
        -webkit-background-clip: text; /* stylelint-disable-line */
      }

      .icon {
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 168px;
      height: 40px;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.04em;
    }

    .cancel-btn {
      border-radius: 8px;
      color: #1e1e1e;
      background-color: #f7f7f7;
    }
  }
}

.custom-tag-popup {
  padding: 14px 16px 16px;
  color: #1e1e1e;

  .icon-close {
    width: 22px;
    height: 22px;
  }

  .title {
    margin-top: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .input {
    width: 100%;
    padding: 12px 0;
    margin-top: 24px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .btn {
    margin-top: 40px;
    border-radius: 2px;
  }
}
</style>

