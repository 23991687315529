<template>
  <div>
    <div class="cmpt-work-card" @click="handleClick">
      <div class="image-wrapper">
        <img :src="imgSrc" alt="" class="card-image" @load="$emit('imgLoad')" />

        <div v-if="card.pinned" class="pinned">置顶</div>
        <div v-if="card.contentType !== TREND_TYPE.VIDEO">
          <span v-if="!card.pinned && card.imgs?.length > 1" class="icon number">1/{{ card.imgs.length }}</span>
          <img @load="$emit('imgLoad')" v-if="card.contentType === TREND_TYPE.WORK" src="@/assets/icon-light.png" alt=""
            class="icon light" />
          <img @load="$emit('imgLoad')" v-else-if="card.contentType === TREND_TYPE.ARTICLE" src="@/assets/icon-edit.png"
            alt="" class="icon edit" />
          <img @load="$emit('imgLoad')"
            v-else-if="card.contentType === TREND_TYPE.COMMON && card.businessType === BUSINESS_TYPE.BUSINESS"
            src="@/assets/icon-bag.png" alt="" class="icon bag" />
        </div>
        <img @load="$emit('imgLoad')" v-else-if="card.contentType === TREND_TYPE.VIDEO"
          src="@/assets/icon-triangles.png" alt="" class="icon triangles" />
      </div>

      <div class="text">
        <span class="text-overflow-2">{{ card.title }}</span>
        <img v-if="showCollected" @click.stop="showDeleteConfirmPopup = true" src="@/assets/icon-collection-fill.png" alt=""
          class="icon collection" />
      </div>
    </div>

    <div v-if="loading" class="loading-box">
      <van-loading size="2rem" vertical />
    </div>

    <van-popup v-model="showDeleteConfirmPopup" :style="{
      width: '24.58333rem',
      borderRadius: '0.16667rem'
    }" @click-overlay="showDeleteConfirmPopup = false">
      <div class="delete-popup">
        <div class="icon-box">
          <img src="@/assets/icon-collection-white.png" class="collection-icon" />
        </div>
        <div class="tips">
          确认要取消收藏“{{`${card.title.length >= 7 ? card.title.slice(0,7) + '...' : card.title}`}}”这条记录？
        </div>
        <div class="buttons">
          <button class="reset-btn confirm-btn" @click="handleCancelCollection">取消收藏</button>
          <button class="reset-btn delete-btn" @click="showDeleteConfirmPopup = false">取消</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { authStore, businessInfo, collectionsStore } from '@/store';
import { DOMAIN_OSS, DOMAIN_OSS2, TREND_TYPE, BUSINESS_TYPE, callbackUrl } from '@/constants/index';

@Component
export default class WorkCard extends Vue {
  @Prop({ type: Object, default: () => ({}) }) card
  @Prop({ type: Number, default: 0 }) secondTab
  @Prop({ type: Boolean, default: false }) showCollected

  TREND_TYPE = TREND_TYPE
  BUSINESS_TYPE = BUSINESS_TYPE

  showDeleteConfirmPopup = false
  loading = false

  get imgSrc() {
    const params = '?x-oss-process=image/resize,l_500,m_lfit/format,jpg';
    let src = '';
    if (this.card.appletFallsImage && this.card.appletFallsImage !== DOMAIN_OSS) {
      src = this.card.appletFallsImage;
    } else {
      src = this.card.coverUri;
    }
    return src.startsWith('http') ? src : `${DOMAIN_OSS2}${src}${params}`;
  }

  async handleClick() {
    const isVideo = this.card.contentType === TREND_TYPE.VIDEO;
    const query = {
      businessId: this.card.userId,
      id: this.card.trendIssueId,
      title: businessInfo.data.name,
      secondTab: this.secondTab,
      staffId: businessInfo.businessUserEmployeeId,
      storeQRCode: businessInfo.storeQRCodeFlag
    };
    const callback = callbackUrl + this.$router.resolve({ name: isVideo ? 'video-detail' : 'label-detail', query }).href;
    const isLogin = await authStore.checkLogin(callback);
    if (!isLogin) { return; }
    this.$router.push({ name: isVideo ? 'video-detail' : 'label-detail', query: {
      id: this.card.trendIssueId,
      title: this.card.title,
      secondTab: this.secondTab,
      businessId: this.card.userId,
      staffId: businessInfo.businessUserEmployeeId,
      storeQRCode: businessInfo.storeQRCodeFlag
    } });
  }

  async handleCancelCollection() {
    this.showDeleteConfirmPopup = false;
    this.loading = true;
    try {
      await collectionsStore.cancelCollect(this.card.trendIssueId);
      collectionsStore.removeItemById(this.card.trendIssueId);
    } catch (e) {
      this.$showToast('取消收藏失败，请重试');
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.cmpt-work-card {
  .image-wrapper {
    position: relative;
    overflow: hidden;

    .card-image {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
    }

    .pinned {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 10px;
      width: 46px;
      height: 22px;
      border-radius: 30px;
      font-weight: 700;
      font-size: 10px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 1px;
      color: #1e1e1e;
      background: #fab536;
    }

    .icon {
      position: absolute;
      z-index: 1;

      &.number {
        top: 10px;
        left: 10px;
        padding: 2px 6px;
        border-radius: 8px;
        font-weight: 700;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.9px;
        color: #595959;
        background-color: #fff;
        opacity: 0.75;
      }

      &.light,
      &.edit,
      &.triangles {
        top: 10px;
        right: 10px;
        width: 16px;
        height: 16px;
      }

      &.bag {
        bottom: 10px;
        left: 10px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
    color: #1e1e1e;
    gap: 4px;

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}

.delete-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 16px 16px;

  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 64px;
    height: 64px;
    padding: 21px;
    border-radius: 50%;
    background: #4c4c4c;

    .collection-icon {
      width: 22px;
      height: 22px;
    }
  }

  .tips {
    margin-top: 19px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    gap: 6px;

    .delete-btn,
    .confirm-btn {
      height: 44px;
      border-radius: 2px;
      font-weight: 500;
      font-size: 14px;
    }

    .confirm-btn {
      color: #fff;
      background: #4c4c4c;
    }
  }
}

.loading-box {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba($color: #f0f0f0, $alpha: 0.3);
}
</style>
