<template>
  <div class="cmpt-tool-btns">
    <div class="btns">
      <div class="item" @click="showShareTips=true">
        <img src="@/assets/icon-share.png" alt="分享" class="icon" />
        <div class="text">分享</div>
      </div>
      <div v-if="showCollection" class="item" @click="handleCollection">
        <img :src="IconCollection" alt="分享" class="icon" />
        <div class="text">{{hasCollection ? '已收藏' : '收藏'}}</div>
      </div>
    </div>

    <div v-if="loading" class="loading-box">
      <van-loading size="2rem" vertical />
    </div>

    <ShareTipsPopup v-model="showShareTips" />
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ShareTipsPopup from '@/components/share-tips-popup.vue';
import IconBlack from '@/assets/tabbar/icon-collection-black.png';
import IconYellow from '@/assets/icon-collection-fill.png';
import { detailStore }  from '@/store';

@Component({
  components: { ShareTipsPopup },
})
export default class ToolBtns extends Vue {
  @Prop({ type: String }) id // 灵感 id
  @Prop({ type: Boolean, default: true }) showCollection

  loading = false
  showShareTips = false

  get hasCollection() {
    return detailStore.data.privateDomainCollect;
  }

  get IconCollection() {
    return this.hasCollection ? IconYellow : IconBlack;
  }

  async handleCollection() {
    try {
      this.loading = true;
      await detailStore.toggleCollection({ targetId: this.id });
      detailStore.updateCollectionStatus();
    } catch (e) {
      this.$showToast('收藏切换失败，请重试');
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .item {
    text-align: center;

    .text {
      font-size: 12px;
      line-height: 18px;
      color: #1e1e1e;
    }

    .icon {
      width: 22px;
      height: 22px;
    }
  }
}

.loading-box {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba($color: #f0f0f0, $alpha: 0.3);
}
</style>
