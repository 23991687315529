import Vue from 'vue';
import { SimpleStore } from './simple-store';

export class Collection extends SimpleStore {
  static defaultParams = {
    page: 1,
    per_page: 10,
    pageSize: 10,
  }

  meta = { total: 0, page: 1, offset: 0 }
  data = []
  $params = Object.assign({}, this.constructor.defaultParams, this.params)

  fetchData(params = {}) {
    this.data = [];
    Object.assign(this.params, params, { page: 1 });
    return this.fetching(async () => {
      const { data, meta } = await this.fetch(this.params);
      this.meta = meta;
      this.data = data.data ? data.data : data;
    });
  }

  fetchMoreData() {
    this.params.page = Math.ceil(this.data.length / this.params.pageSize + 1);
    return this.fetching(async () => {
      const { data, meta } = await this.fetch(this.params);
      this.meta = meta;
      this.data.push(...(data.data ? data.data : data));
    });
  }

  resetData() {
    this.isFulfilled = false;
    this.data = [];
  }

  get params() {
    return this.$params;
  }

  set params(properties) {
    this.$params = Object.assign({}, this.constructor.defaultParams, properties);
  }

  get isComplete() {
    const total = this.meta?.total;
    const { page, pageSize } = this.params;
    if (total === undefined) {
      return this.isFulfilled && Boolean(page * pageSize > this.data.length);
    }

    return this.isFulfilled && this.data.length >= total;
  }

  get isEmpty() {
    return this.isFulfilled && this.data.length === 0;
  }

  removeItemById(id) {
    const index = this.data.findIndex(item => Number(item.id) === Number(id));
    if (index !== -1) {
      this.data.splice(index, 1);
    }
  }
}

Vue.prototype.$Collection = Collection;
