<template>
  <div class="empty flex flex-col items-center">
    <img class="empty-img" :src="emptyIcon" />
    <div class="title">
      {{ title }}
    </div>
    <div class="subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
  import EmptyIcon from '@/assets/list-empty.png';

  export default {
    props: {
      title: {
        type: String, default: '您没有任何记录'
      },
      subtitle: {
        type: String, default: '在首页可以使用小鸭AI装修设计你的家'
      },
      emptyIcon: {
        type: String, default: EmptyIcon
      }
    }
  };
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: auto 0;

  .empty-img {
    width: 225px;
    margin-bottom: 44px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: transparent;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    -webkit-background-clip: text; /* stylelint-disable-line */
  }

  .subtitle {
    width: 60%;
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}
</style>
