import Vue from 'vue';
import { Dialog, Toast } from 'vant';

const defaultOptions = {
  closeOnClickOverlay: true,
  confirmButtonText: '确定',
  confirmButtonColor: '#576B95',
  cancelButtonColor: '#000000',
};

export function alert(params) {
  let options = {};
  if (typeof params === 'string') {
    options = {
      message: params,
    };
  } else {
    options = params;
  }
  return Dialog.alert(Object.assign({}, defaultOptions, options));
}

export function confirm(params) {
  let options = {};
  if (typeof params === 'string') {
    options = {
      message: params,
    };
  } else {
    options = params;
  }
  return Dialog.confirm(Object.assign({}, defaultOptions, options));
}

export function autoLoadingDecorator(target, name, descriptor) {
  const func = descriptor.value;
  descriptor.value = function () {
    return autoLoading(func.apply(this, arguments));
  };
}

autoLoadingDecorator.retry = function(target, name, descriptor) {
  const func = descriptor.value;
  descriptor.value = function () {
    return autoLoading(() => func.apply(this, arguments), { isRetry: true });
  };
};

export function autoLoading(target, options = {}) {
  const action = target instanceof Function ? target() : target;
  // 不是promise时就不要loading
  if (!(action instanceof Promise)) {
    return action;
  }
  Toast.loading({ message: options.loadingText || '加载中', duration: 0 });
  return action
    .finally(() => {
      Toast.clear();
    })
    .catch(err => {
      if (options.isRetry) {
        handleRetry(err, target, options);
      } else {
        errHandle(err);
      }
    });
}

function handleRetry(err, target, options) {
  const ignoreErrors = /(cancel|ignore|请先登录)/i;
  const msg = err.message || err.errMsg;
  if (msg && !ignoreErrors.test(msg)) {
    return alert({
      title: '提示',
      message: msg,
      confirmButtonText: '重试'
    }).then(({ confirm }) => {
      if (confirm) {
        return autoLoading(target, options);
      }
    });
  }
  throw err;
}

export function errHandle(err) {
  const ignoreErrors = /(cancel|ignore|请先登录)/i;
  const timeoutErrors = /(request:fail timeout)|(timeout.*\d+ms)/i;
  let msg = err.message || err.errMsg;
  msg = err.status >= 500 || timeoutErrors.test(msg) ? '网络开小差了，请稍后再试' : msg;
  if (!ignoreErrors.test(msg)) {
    msg && alert({
      title: '提示',
      message: msg,
    });
  }
  throw err;
}

Vue.prototype.$autoLoading = autoLoading;
Vue.autoLoading = autoLoadingDecorator;
