<template>
  <div>
    <ContactInfoListPopup
      v-if="showContactInfoList"
      :show="show"
      :contactInfo="contactInfo"
      @update:show="$emit('update:show', false)"
      @custom-click="saleInfo = $event"
    />
    <ContactInfoDetailPopup
      :show="showContactInfoDetailPopup"
      :saleInfo="saleInfo || contactInfo[0]"
      @update:show="closeDetailPopup"
    />
  </div>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ContactInfoListPopup from '@/components/contact-info-list-popup';
import ContactInfoDetailPopup from '@/components/contact-info-detail-popup';
import { businessUserEmployeeId, storeQRCodeFlag } from '@/store';

@Component({
  components: { ContactInfoListPopup, ContactInfoDetailPopup }
})
export default class ContactInfoPopup extends Vue {
  @Prop({ type: Boolean, default: false }) show
  @Prop({ type: Array, default: () => [] }) contactInfo

  saleInfo = null

  get showContactInfoList() {
    if (!storeQRCodeFlag && !businessUserEmployeeId) { // 门店分享：卡片
      return false;
    }
    if (businessUserEmployeeId) { // 员工码：未离职显示卡片、已经离职显示列表
      return this.contactInfo.length > 1 ? true : false;
    }
    if (storeQRCodeFlag) { // 门店码：显示列表
      return true;
    }
    return true;
  }

  closeDetailPopup() {
    if (!this.showContactInfoList) {
      this.$emit('update:show', false);
    } else {
      this.saleInfo = null;
    }
  }

  get showContactInfoDetailPopup() {
    return this.showContactInfoList ? this.saleInfo : this.show;
  }
}
</script>
