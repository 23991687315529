<template>
  <div class="upload-img-box" :class="{ 'no-padding': !!image }">
    <img v-if="image" :src="image" class="image" mode="aspectFit" @click="showFlowTipsPopup = true">
    <template v-else>
      <button
        class="primary-btn upload-btn"
        :class="{ shake: shake }"
        @click="showFlowTipsPopup = true"
        @animationend="$emit('update:shake', false)"
      >
        <img src="@/assets/icon-upload.png" class="icon" />
        上传图片
      </button>
      <div class="tips">
        上传图片，选择喜欢的风格10秒AI为你设计新家！
      </div>
    </template>

    <div v-if="showFlowTipsPopup" class="flow-tips-popup" :style="{ height: getContainerHeight() }">
      <div class="flow-tips-wrapper" @click="showFlowTipsPopup = false">
        <div class="flow-tips" @click.stop>
          <img src="@/assets/flow-tips.png" alt="" class="image" />
        </div>
      </div>

      <div class="action-content" @click.stop>
        <div class="example" v-if="exampleList?.data.length > 0">
          <div class="title">没有想法？试试示例图</div>
          <div class="example-list">
            <div class="item" v-for="item in exampleList?.data" :key="item.id" @click="selectedExample(item)">
              <img :src="item.image.url" class="image" />
              <div class="desc">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="action">
          拍照上传
          <input
            type="file"
            accept="image/png,image/jpg"
            capture="camera"
            class="upload-input"
            @change="handleUpload"
          >
        </div>
        <div class="action">
          相册选图
          <input
            type="file"
            accept="image/*"
            class="upload-input"
            @change="handleUpload"
          >
        </div>
        <div class="action" @click="showFlowTipsPopup = false">
          取消
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Model } from 'vue-property-decorator';
  import { getBrowserInterfaceSize } from '@/utils';
  import { authStore, exampleList } from '@/store';
  import { ORIGIN_SRC } from '@/constants/index';

  @Component
  export default class UploadImage extends Vue {
    @Model('update:shake', { type: Boolean, default: false }) shake;

    authStore = authStore
    exampleList = exampleList

    showFlowTipsPopup = false
    image = ''

    selectedExample(item) {
      this.showFlowTipsPopup = false;
      const { url, signed_id } = item.image;
      this.image = url;
      this.$emit('change', { url, signed_id });
      sessionStorage.setItem(ORIGIN_SRC, JSON.stringify({ url, signed_id }));
    }

    getContainerHeight() {
      const { pageHeight } = getBrowserInterfaceSize();
      return `${pageHeight}px`;
    }

    async handleUpload(e) {
      this.showFlowTipsPopup = false;
      const file = e.target.files[0];
      const reader =  new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        sessionStorage.setItem(ORIGIN_SRC, JSON.stringify({ base64: e.target.result }));
      };
      reader.readAsDataURL(file);
      this.$emit('change', file);
    }

    mounted() {
      const originSrc = JSON.parse(sessionStorage.getItem(ORIGIN_SRC) || null);
      this.image = originSrc?.base64 || originSrc?.url || '';
    }
  }
</script>

<style lang="scss" scoped>
@keyframes upload-btn-shake {
  0%, 100% { transform: rotate(0); }

  15%, 45%, 75% { transform: rotate(-10deg); }

  30%, 60%, 90% { transform: rotate(10deg); }
}

.upload-img-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  color: #1e1e1e;
  background-clip: padding-box;
  background-color: #fff;

  &.no-padding {
    padding: 0;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: -1px;
    border-radius: inherit;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    content: '';
  }

  .image {
    width: 100%;
    height: 144px;
    object-fit: contain;
  }

  .upload-btn {
    width: auto;
    padding: 8px 22px;
    font-weight: 700;
    font-size: 16px;
    gap: 4px;

    &.shake {
      animation: upload-btn-shake 0.5s ease-in-out;
    }

    .icon {
      width: 17px;
      height: 16px;
    }
  }

  .tips {
    width: 172px;
    margin-top: 12px;
    font-size: 14px;
    text-align: center;
    color: #828282;
  }
}

.flow-tips-popup {
  position: fixed;
  z-index: z('modal', 'base');
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $h5-max-width;
  background: rgba(24, 24, 24, 0.7);

  .flow-tips-wrapper {
    flex: 1;
    height: 0;
    padding: 13px;

    .flow-tips {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .action-content {
    padding-bottom: env(safe-area-inset-bottom);
    background: #fff;

    .example {
      padding-top: 16px;
      border-bottom: 1px solid #eeeeee;

      .title {
        padding: 0 13px;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.28px;
      }

      .example-list {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 0 13px;
        padding-bottom: 16px;
        overflow-x: scroll;
        gap: 6px;

        &::-webkit-scrollbar {
          display: none;//隐藏滚动条
        }

        .item {
          flex-shrink: 0;
          box-sizing: border-box;
          width: 91px;

          .image {
            height: 72px;
            border-radius: 8px;
            object-fit: cover;
          }

          .desc {
            margin-top: 6px;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.78px;
          }
        }
      }
    }

    .action {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      padding-top: 12px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
      font-size: 16px;
      text-align: center;
      color: #1e1e1e;

      &:last-child {
        border-bottom: none;
      }

      .upload-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
