<template>
  <div class="search-page" ref="searchPage" @scroll="handlePageScroll">
    <v-title value="搜索" />

    <SearchBox :modelValue="inputValue" @search="onHandleSearch" @focus="closeResult" @cancel="onHandleCancel"
      @change="onHandleChange" @update:modelValue="onUpdateInputValue" @back="onHandleBack" />

    <SearchQuickly v-if="!showResult" type="quickly" :data="inputValue ? (relationStore.data || []) : []"
      @search="onHandleSearch" />
    <SearchResult v-else-if="showResult" :store="searchStore" />
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import SearchBox from './components/search-box.vue';
import SearchQuickly from './components/search-quickly.vue';
import SearchResult from './components/search-result.vue';
import { relationStore, searchStore, authStore } from '@/store';
import { debounce, omit } from 'lodash';
import PageLoading from '@/components/page-loading.vue';

@Component({
  name: 'Search',
  components: { SearchBox, SearchQuickly, SearchResult, PageLoading }
})
export default class Search extends Vue {
  relationStore = relationStore
  searchStore = searchStore

  inputValue = '';
  showResult = false;
  code = ''
  searchPageScrollPage = 0

  async mounted() {
    this.code = this.$route.query.code;
    if (this.code) {
      await authStore.login();
      this.$router.replace({ query: omit(this.$route.query, 'code') });
    }
  }

  onHandleBack() {
    if (this.code) {
      const { tab, secondTab } = this.$route.query;
      this.$router.push({ name: 'business-home', query: { tab, secondTab } });
    } else {
      this.$router.go(-1);
    }
  }

  // search request
  async onHandleSearch(content) {
    if (content) {
      this.inputValue = content;
      this.showResult = true;
      await searchStore.fetchData({ text: content });
    }
  }

  // quickly search
  debouncedFetchData = debounce((text) => {
    relationStore.fetchData(text);
  }, 300);
  onHandleChange(text) {
    if (text) {
      this.debouncedFetchData({ text }); // 调用防抖函数
    }
  }

  // input value change
  onUpdateInputValue(text) {
    if (!text) {
      relationStore.resetData();
    }
    this.inputValue = text;
    this.closeResult();
  }

  closeResult() {
    if (this.showResult) {
      this.showResult = false;
    }
  }

  onHandleCancel() {
    this.inputValue = '';
    this.closeResult();
  }

  handlePageScroll() {
    if (this.$refs.searchPage) {
      this.searchPageScrollPage = this.$refs.searchPage.scrollTop;
    }
  }
  async activated() {
    await this.$nextTick();
    if (this.$refs.searchPage) {
      this.$refs.searchPage.scrollTop = this.searchPageScrollPage; // 在页面重新进入时恢复滚动位置
    }
    this.wxShare({ canShare: false });
  }
}
</script>

<style lang="scss" scoped>
.search-page {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  padding-bottom: env(safe-area-inset-bottom);
  gap: 12px;
}
</style>
