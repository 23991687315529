<template>
  <van-list
    v-model="loading"
    :error.sync="error"
    :finished="store.isComplete"
    :finished-text="store.isFetching || isEmpty ? '' : noMoreText"
    @load="handleLoad"
  >
    <!-- 列表内容 -->
    <slot />

    <!-- loading -->
    <template v-if="store.isFetching">
      <div class="loading-box">
        <van-loading size="2rem" vertical>加载中...</van-loading>
      </div>
    </template>
    <template #loading>
      <div></div>
    </template>

    <!-- 没有数据 -->
    <template v-if="isEmpty">
      <slot v-if="$slots.empty" name="empty" />
      <div v-else class="empty-box no-data">
        <span class="text">{{ emptyText }}</span>
      </div>
    </template>

    <!-- 错误信息 -->
    <template #error>
      <div class="error-box">
        <van-button class="reload-btn">
          加载失败，点击重试!
        </van-button>
      </div>
    </template>
  </van-list>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isAxiosError, AxiosError } from 'axios';

@Component
export default class InfiniteList extends Vue {
  @Prop({ type: Object, required: true, default: () => ({}) }) store;
  @Prop({ type: String, default: '暂无数据' }) emptyText;
  @Prop({ type: String, default: '没有更多了 ~' }) noMoreText;

  loading = false;
  error = false;

  get isEmpty() {
    return !this.store.isFetching && this.store.isEmpty;
  }

  async handleLoad() {
    try {
      this.store.fetchMoreData && await this.store.fetchMoreData();
      this.$emit('more');
    } catch (err) {
      this.error = true;

      if (isAxiosError(err)) {
        // 网络异常
        if (err.code === AxiosError.ERR_NETWORK) {
          this.$alert('网络异常，请检查网络连接');
          throw err;
        }

        // 请求超时
        if (err.code === AxiosError.ECONNABORTED || err.code === AxiosError.ETIMEDOUT) {
          this.$alert('请求超时，请稍后重试');
          throw err;
        }

        const msgText = err.response?.data.error_message || err.response?.data.error;
        this.$alert(msgText);
      } else if (err instanceof Error) {
        this.$alert(err.message);
      }

      throw err;
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-box {
  padding: 30px 0;
  text-align: center;
}

.empty-box {
  padding: 30px 0;
  line-height: 24px;
  text-align: center;
}

.error-box {
  padding: 30px 0;

  .reload-btn {
    margin: 0 auto;
  }
}
</style>
