<template>
  <div class="cmpt-sheet">
    <van-popup
      :value="value"
      :overlay-style="{ backgroundColor: 'rgba(24, 24, 24, 0.8)' }"
      position="bottom"
      @click-overlay="handleClose"
      @close="handleClose"
    >
      <div class="sheet-wrapper">
        <div class="action" @click="handleShare">
          分享给朋友
        </div>
        <div class="action" @click="handleShare">
          分享给朋友圈
        </div>
        <div class="action" @click="() => {
          handleClose();
          $router.push({ name: 'aiSharePoster', query: { code: detail.code } });
        }">
          生成海报
        </div>
        <div class="action" @click="handleClose">
          取消
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Vue, Component, Model, Prop } from 'vue-property-decorator';

  @Component
  export default class ShareSheet extends Vue {
    @Model('input', { type: Boolean, default: false }) value;
    @Prop({ type: Boolean, default: false }) showShareTips;
    @Prop({ type: Object, default: () => ({}) }) detail

    get localShowShareTips() {
      return this.showShareTips;
    }
    set localShowShareTips(val) {
      this.$emit('update:showShareTips', val);
    }

    handleClose() {
      this.$emit('input', false);
    }

    handleShare() {
      this.$emit('update:showShareTips', true);
      this.$emit('input', false);
    }
  }
</script>

<style lang="scss" scoped>
.cmpt-sheet {
  :deep(.van-overlay) {
    left: 50%;
    max-width: $h5-max-width;
    transform: translateX(-50%);
  }
}

.sheet-wrapper {
  .action {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding-top: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    font-size: 16px;
    text-align: center;
    color: #1e1e1e;

    &:last-child {
      border-bottom: none;
    }
  }

  @include paddingBottomSafeArea();
}
</style>
