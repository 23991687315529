<template>
  <div v-if="showTabbar" class="cmpt-tabbar-box">
    <div class="cmpt-tabbar">
      <div
        :class="['item', { active: getSelected(item) }]"
        v-for="item in list"
        :key="item.pagePath"
        @click="handleNav(item)"
      >
        <div class="icon-box">
          <img class="icon" mode="scaleToFill" :src="getSelected(item) ? item.selectedIconPath : item.iconPath" />
        </div>

        <div class="text" :class="{business: page==='business'}">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { authStore } from '@/store';

  @Component
  export default class TabBar extends Vue {
    @Prop({ type: 'ai' | 'business', default: 'ai' }) page;

    get list() {
      return this.page === 'ai' ? [
        {
          route: { name: 'aiHome' },
          text: '首页',
          iconPath: require('@/assets/tabbar/icon-home.png'),
          selectedIconPath: require('@/assets/tabbar/icon-home-selected.png'),
        },
        {
          route: { name: 'aiRecord' },
          text: '记录',
          iconPath: require('@/assets/tabbar/icon-record.png'),
          selectedIconPath: require('@/assets/tabbar/icon-record-selected.png'),
        }
      ] : [
        {
          route: { name: 'business-home' },
          text: '首页',
          iconPath: require('@/assets/tabbar/icon-home.png'),
          selectedIconPath: require('@/assets/tabbar/icon-home-black.png'),
        },
        {
          route: { name: 'collection' },
          text: '收藏夹',
          iconPath: require('@/assets/tabbar/icon-collection.png'),
          selectedIconPath: require('@/assets/tabbar/icon-collection-black.png'),
        }
      ];
    }

    get showTabbar() {
      return this.list.map(item => item.route.name).includes(this.$route.name);
    }

    getSelected(item) {
      return this.$route.name === item.route.name;
    }

    async handleNav(item) {
      if (!authStore.isLogin) {
        this.$emit('login');
        return;
      }
      this.$router.replace(item.route);
    }
  }
</script>

<style lang="scss" scoped>
  .cmpt-tabbar-box {
    $content-height: var(--tabbar-height);
    z-index: 1;

    .cmpt-tabbar {
      display: flex;
      justify-content: space-between;
      height: $content-height;
      padding-bottom: env(safe-area-inset-bottom);
      background: #fff;

      .item {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.active {
          font-weight: 700;

          .text {
            color: #f98909;
          }

          .business {
            color: #000;
          }
        }

        .icon-box {
          position: relative;
          width: 24px;
          height: 24px;

          .icon {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          margin-top: 4px;
          font-size: 12px;
          white-space: nowrap;
          color: #9e9e9e;
        }
      }
    }
  }
</style>
