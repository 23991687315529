<template>
  <div class="loading-page">
    <slot></slot>
    <!-- loading -->
    <template v-if="isLoading || forceLoading">
      <div class="info-box loading">
        <van-loading size="2rem" vertical>加载中...</van-loading>
      </div>
    </template>

    <!-- 错误信息 -->
    <template v-if="isError">
      <div class="info-box error">
        <van-button @click="handleLoad">
          加载失败，点击重试!
        </van-button>
        <van-button v-if="businessInfo.businessUserId || $route.path !== '/'" @click="handleBack" >
          回到首页
        </van-button>
      </div>
    </template>

  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { businessInfo } from '@/store';

@Component
export default class PageLoading extends Vue {
  @Prop({ type: Function, required: true, default: () => Promise.resolve() }) onFetch;
  @Prop({ type: Boolean, default: false }) forceLoading;

  businessInfo = businessInfo

  isLoading = false
  isError = false

  async mounted() {
    await this.handleLoad();
  }

  async handleLoad() {
    try {
      this.isLoading = true;
      await this.onFetch();
      this.isLoading = false;
    } catch (e) {
      let errMsg = e.message || '加载失败';
      this.isLoading = false;
      this.isError = true;
      Promise.reject(new Error(errMsg));
      if (e.status === 404) {
        errMsg = '页面不存在';
      }
      this.$showToast(errMsg);
    }
  }

  handleBack() {
    this.$router.push({ name: 'business-home' });
  }
}
</script>

<style lang="scss" scoped>
.loading-page {
  .info-box {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 10px 20px;
    text-align: center;
    background: #fff;
  }
}
</style>
