import { requestApp } from '@/utils';
import { Collection } from './helper/collection';
import { businessInfo } from './business';

export class CollectionsStore extends Collection {
  static defaultParams = {
    pageSize: 39,
    pageIndex: 1,
  }

  get isComplete() {
    return (this.data.total || 0) <= this.params.pageIndex * this.params.pageSize;
  }

  get isEmpty() {
    return this.data.list?.length === 0;
  }

  async fetch() {
    return await requestApp.get(`/user/operation/getCollectList/${this.params.pageIndex}/${this.params.pageSize}?businessUserId=${businessInfo.businessUserId}`);
  }

  async fetchMoreData() {
    this.params.pageIndex++;
    const { data } = await this.fetch();
    this.data?.list?.push(...data.data?.list);
  }

  async cancelCollect(id) {
    await requestApp.delete(`user/operation/cancelCollect/${id}`);
  }

  removeItemById(id) {
    const index = this.data.list.findIndex(item => Number(item.trendIssueId) === Number(id));
    if (index !== -1) {
      this.data.list.splice(index, 1);
    }
  }
}
export const collectionsStore = new CollectionsStore();
