<template>
  <div class="cmpt-inquiry" @click="$emit('contact-click')">
    <div class="avatar">
      <img :src="storeQRCodeFlag ? getSrc({ src: businessInfo?.data?.headPic }) : headSrc" alt="avatar" class="image" />
    </div>
    <div class="text flex-1">
      {{ infoText }}
    </div>
    <div class="btn-inquiry">
      点我咨询
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import defaultAvatar from '@/assets/head.svg';
import { businessInfo, storeQRCodeFlag } from '@/store';
import { BUSINESS_TYPE } from '@/constants/index';
import { getSrc } from '@/utils';

@Component
export default class Inquiry extends Vue {
  @Prop({ type: String }) headSrc

  businessInfo = businessInfo
  defaultAvatar = defaultAvatar
  storeQRCodeFlag = storeQRCodeFlag
  getSrc = getSrc

  get infoText() {
    if (businessInfo.data.businessType === BUSINESS_TYPE.CREATOR) {
      return '联系创作者';
    }
    if (businessInfo.data.businessType === BUSINESS_TYPE.BUSINESS) {
      return '联系商家领取优惠！';
    }
    return '';
  }
}
</script>

<style lang="scss" scoped>
.cmpt-inquiry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 3px 22px 3px 3px;
  border-radius: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  white-space: nowrap;
  letter-spacing: 0.36px;
  color: $white;
  background-color: $black;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 84%);

  @include media-xxs-only {
    padding: 3px 18px 3px 3px;
    font-size: 16px;
  }

  .avatar {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;

    .image {
      width: 100%;
      height: 100%;
    }

    @include media-xxs-only {
      width: 36px;
      height: 36px;
    }
  }

  .btn-inquiry {
    padding: 4px 14px;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.24px;
    color: $primary-color;
    background-color: #fff;

    @include media-xxs-only {
      font-size: 10px;
      line-height: 14px;
    }
  }
}
</style>
