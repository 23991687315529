<template>
  <div>
    <div class="record-card" @click.prevent="$router.push({ name: 'aiResultDetail', query: { code: record.code } })">
      <div class="images">
        <div
          class="image-item"
          v-for="(item, index) in record.results"
          :key="item.id"
          :style="{
            paddingBottom: paddingBottom
          }"
          @click="$router.push({ name: 'aiResultDetail', query: { code: record.code, index } })"
        >
          <img
            :src="item.url"
            class="image"
            mode="aspectFill"
          />
      </div>
      </div>

      <div class="text-wrapper">
        <span class="title text-overflow-2">
          {{ record.title }}
        </span>
        <img src="@/assets/icon-trash.png" class="trash-icon" @click.stop="showDeleteConfirmPopup = true" />
      </div>
    </div>

    <van-popup
      v-model="showDeleteConfirmPopup"
      :style="{
        width: '24.58333rem',
        borderRadius: '0.16667rem'
      }"
      @click-overlay="showDeleteConfirmPopup = false"
    >
      <div class="delete-popup">
        <div class="icon-box">
          <img src="@/assets/icon-trash-white.png" class="trash-icon" />
        </div>
        <div class="tips">
          确认要删除“{{ `${record.title.slice(0,7)}...` }}”这条记录？
        </div>
        <div class="buttons">
          <button class="primary-btn confirm-btn" @click="handleDeleteRecord">删除</button>
          <button class="primary-btn delete-btn" @click="showDeleteConfirmPopup = false">取消</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { imagesStore } from '@/store';

  @Component()
  export default class RecordCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) record

    imagesStore = imagesStore
    showDeleteConfirmPopup = false
    imageInfo = {}

    get paddingBottom() {
      // 先将高度撑起来，防止页面高度因为图片加载变化
      const { width = 0, height = 0 } = this.record.raw.metadata;
      return `${height / width * 100}%`;
    }

    handleDeleteRecord() {
      imagesStore.deleteRecord(this.record.code);
      this.showDeleteConfirmPopup = false;
    }
  }
</script>

<style lang="scss" scoped>
.record-card {
  width: 100%;
  overflow: hidden;

  .images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 8px;
    overflow: hidden;
    gap: 5px;

    .image-item {
      position: relative;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        vertical-align: top;
        pointer-events: none;
      }
    }
  }

  .text-wrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    .title {
      width: calc(100% - 20px);
    }

    .trash-icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }
}

.delete-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 16px 16px;

  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 64px;
    height: 64px;
    padding: 21px;
    border-radius: 50%;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);

    .trash-icon {
      width: 21px;
      height: 21px;
    }
  }

  .tips {
    margin-top: 19px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    gap: 6px;

    .confirm-btn,
    .delete-btn {
      height: 44px;
      border-radius: 2px;
      font-weight: 500;
      font-size: 14px;
    }

    .delete-btn {
      color: #1e1e1e;
      background-image: none;
    }
  }
}
</style>
