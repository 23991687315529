<template>
  <div class="cmpt-presentations-wrapper" @scroll="handleScroll" ref="introductionRef">
    <div v-if="(store.data.shopEnvironment && store.data.shopEnvironment.length > 0) || showDataDetail"
      class="cmpt-presentations">
      <div v-if="store.data.shopEnvironment && store.data.shopEnvironment.length > 0"
        class="presentations-box environment" @click="handleNav">
        <div class="title">
          <span class="text">店铺环境</span>
          <div class="more">
            <span>全部</span>
            <span class="icon-more"></span>
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) in store.data.shopEnvironment" :key="index" class="image-wrapper">
            <img :src="getSrc({ src: item, suffix: '?x-oss-process=image/resize,w_500,m_lfit/format,jpg', isAvatar: false })" alt=""
              class="image" />
          </div>
        </div>
      </div>
      <div v-if="showDataDetail" class="presentations-box detail-info">
        <div v-for="info in dataDetail" :key="info.id">
          <div class="title">
            {{ info.title }}
          </div>
          <div v-if="info.type === 'list'" class="list">
            <div v-for="(content, index) in info.content" :key="index" class="list-item">
              <span class="dot"></span>
              <span class="text">{{ content }}</span>
            </div>
          </div>
          <div v-else-if="info.type === 'text'" class="text">
            <p v-html="info.content"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!store.isFulfilled || store.isFetching">
      <div class="loading-box">
        <van-loading size="2rem" vertical>加载中...</van-loading>
      </div>
    </div>
    <div v-else class="no-data">
      暂无数据
    </div>

    <Support class="support" />
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import WorkCard from '@/components/work-card.vue';
import InfiniteList from '@/components/infinite-list.vue';
import { getSrc } from '@/utils';
import Support from '@/components/support.vue';

@Component({
  components: { WorkCard, InfiniteList, Support },
})
export default class Introduction extends Vue {
  @Prop({ type: Object, default: () => ({}) }) store;
  @Prop({ type: Number }) tab

  getSrc = getSrc
  contentScrollTop = 0

  handleScroll() {
    this.contentScrollTop = this.$refs.introductionRef.scrollTop;
    this.$emit('scroll', this.contentScrollTop);
  }
  async activated() {
    await this.$nextTick();
    this.$refs.introductionRef.scrollTop = this.contentScrollTop;
  }

  get dataDetail() {
    return [
      this.store.data.authenticationNames && this.store.data.authenticationNames.length > 0 && {
        id: 0,
        type: 'list',
        title: '好看鸭认证',
        content: this.store.data.authenticationNames,
      },
      this.store.data.desc && {
        id: 1,
        type: 'text',
        title: '品牌故事',
        content: this.store.data.desc,
      },
      this.store.data.awards && this.store.data.awards.length > 0 && {
        id: 2,
        type: 'list',
        title: '获奖',
        content: this.store.data.awards,
      },
      this.store.data.exhibitions && this.store.data.exhibitions.length > 0 && {
        id: 3,
        type: 'list',
        title: '重要展览',
        content: this.store.data.exhibitions,
      },
    ].filter(Boolean);
  }

  get showDataDetail() {
    return (this.store.data.authenticationNames && this.store.data.authenticationNames.length > 0)
      || (this.store.data.desc)
      || (this.store.data.awards && this.store.data.awards.length > 0)
      || (this.store.data.exhibitions && this.store.data.exhibitions.length > 0);
  }

  handleNav() {
    if (this.tab) { // 记录首页的tab
      this.$router.replace({ query: { ...this.$route.query, tab: this.tab } });
    }
    this.$router.push({ name: 'store-environment', query: { data: this.store.data.shopEnvironment } });
  }
}
</script>

<style lang="scss" scoped>
.cmpt-presentations-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 92px;
  overflow: scroll;
}

.cmpt-presentations {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;

  .presentations-box {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 1px 6px 0px rgba(136, 136, 136, 0.14);
  }

  .environment {
    .title {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.28px;
      color: #1e1e1e;
      box-shadow: 0px 2px 8px 0px rgba(83, 83, 83, 0.05);

      .more {
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 18px;
        color: #8c8c8c;

        .icon-more {
          width: 5px;
          height: 5px;
          margin: 5px;
          border: 1px solid #8c8c8c;
          border-bottom: none;
          border-left: none;
          transform: rotate(45deg);
        }
      }
    }

    .content {
      display: flex;
      padding: 12px 16px;
      overflow: scroll;
      gap: 8px;

      &::-webkit-scrollbar {
        display: none;//隐藏滚动条
      }

      .image-wrapper {
        position: relative;
        padding-right: 89px;
        padding-bottom: 76px;
        border-radius: 6px;
        overflow: hidden;
        background-color: #f0f0f0;

        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .detail-info {
    padding: 0 16px 12px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.24px;
    color: #8c8c8c;

    .title {
      border-bottom: 1px solid #d9d9d9;
      font-weight: 700;
      font-size: 14px;
      line-height: 40px;
      letter-spacing: 0.28px;
      color: #1e1e1e;
    }

    .text {
      white-space: pre-line;
    }

    .list {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      .list-item {
        display: flex;
        align-items: center;
        gap: 13px;

        .dot {
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #595959;
        }
      }
    }
  }

  .loading-box {
    padding: 30px 0;
    text-align: center;
  }

  .support {
    margin: 25px 0 92px;
  }
}
</style>
