import { requestApp } from '@/utils/request';
import { Collection } from './helper/collection';
import { businessInfo } from '@/store/business';
import { TREND_TYPE } from '@/constants/index';

export class WorkListStore extends Collection {
  async fetch(params) {
    return await requestApp.post('/homePage/queryH5TrendAndWorkPage', { businessUserId: businessInfo.businessUserId, ...params });
  }
}

export const workListStore = {
  all: new WorkListStore({ params: { contentType: TREND_TYPE.ALL } }),
  common: new WorkListStore({ params: { contentType: TREND_TYPE.COMMON } }),
  video: new WorkListStore({ params: { contentType: TREND_TYPE.VIDEO } }),
  work: new WorkListStore({ params: { contentType: TREND_TYPE.WORK } }),
  article: new WorkListStore({ params: { contentType: TREND_TYPE.ARTICLE } }),
};
