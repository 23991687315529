import { Collection } from './helper/collection';
import { requestAi } from '@/utils';

class ExampleListStore extends Collection {
  async fetch(params) {
    this.params.per_page = 100;
    return requestAi.get('sample_images', { params });
  }
}

export const exampleList = new ExampleListStore();
