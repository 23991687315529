<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Layout extends Vue {
  includeComponents=['Home', 'Search', 'Albums']
}
</script>

<template>
  <keep-alive :include="includeComponents">
    <router-view class="route-view" />
  </keep-alive>
</template>
