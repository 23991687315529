<template>
  <div class="page">
    <v-title :value="businessUserTitle" />
    <PageLoading :onFetch="onFetch" />

    <div class="page-container">
      <div class="content">
        <div class="store-card" ref="storeCardRef">
          <StoreCard :info="businessInfo.data" />
        </div>
        <div class="fixed-box"
          :style="{ top: panelTop + 'px' }"
          @touchstart="touchStart"
          @touchmove="touchMove"
        >
          <div class="container">
            <div class="tab-bar">
              <TabBar v-model="activeTabKey" :tabs="tabs" @search-click="handleSearchClick" />
            </div>
            <div class="content">
              <van-swipe class="my-swipe" indicator-color="#1e1e1e" @change="handleSwipeChange" v-model="activeTabKey"
                :initial-swipe="activeTabKey" :loop="false" :duration="1000">
                <van-swipe-item :key="0">
                  <div class="cmpt-ideas">
                    <Ideas
                      ref="ideasRef"
                      v-model="activeSecondTabKey"
                      :tabs="secondTabs"
                      :store="currentSecondTabStore"
                      @scroll="handleContentScroll"
                    />
                  </div>
                </van-swipe-item>
                <van-swipe-item :key="1">
                  <div class="cmpt-works">
                    <Works :store="currentTabStore" :tab="1" @scroll="handleContentScroll"/>
                  </div>
                </van-swipe-item>
                <van-swipe-item :key="2">
                  <div class="cmpt-presentations">
                    <Introduction :tab="2" :store="introductionStore" @scroll="handleContentScroll"/>
                  </div>
                </van-swipe-item>
                <template #indicator>
                  <div></div>
                </template>
              </van-swipe>
            </div>
          </div>
        </div>
      </div>
      <!-- AI 入口 -->
      <div class="ai-enter" @click="$router.push({ name: 'aiHome' })">
        AI设计
      </div>
      <!-- 分享悬浮按钮 -->
      <div class="tool-btns">
        <ToolBtns :show-collection="false" />
      </div>
      <PageTabBar page="business" />
    </div>

    <ContactInfoPopup :show="showSaleInfoPopup" :contact-info="businessInfo.data.contactInfos"
      @update:show="showSaleInfoPopup = $event" />
  </div>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator';
import { businessInfo, workListStore, introductionStore, authStore, submitContact, privateDomainAction } from '@/store';
import { getSrc } from '@/utils';
import { callbackUrl, BUSINESS_TYPE, DOMAIN_ACTION } from '@/constants/index';
import StoreCard from '@/components/store-card.vue';
import TabBar from './components/tab-bar.vue';
import Ideas from './components/ideas.vue';
import Works from './components/works.vue';
import Introduction from './components/introduction.vue';
import Inquiry from '@/components/inquiry.vue';
import ContactInfoPopup from '@/components/contact-info-popup.vue';
import PageLoading from '@/components/page-loading.vue';
import { omit, lowerCase, debounce } from 'lodash';
import PageTabBar from '@/components/tabbar.vue';
import ToolBtns from '@/views/business/detail/components/tool-btns.vue';

@Component({
  name: 'Home',
  components: { StoreCard, TabBar, Ideas, Works, Introduction, Inquiry, ContactInfoPopup, PageLoading, PageTabBar, ToolBtns },
})
export default class Home extends Vue {
  authStore = authStore
  businessInfo = businessInfo // 商家信息
  workListStore = workListStore // 灵感&作品
  introductionStore = introductionStore // 介绍
  getSrc = getSrc
  lowerCase = lowerCase

  showSaleInfoPopup = false // 销售弹窗
  activeTabKey = 0
  activeSecondTabKey = 0
  businessUserTitle = businessInfo.businessUserTitle
  homePageScrollPage = 0
  code = ''
  baseQuery = {
    businessId: businessInfo.businessUserId,
    title: businessInfo.businessUserTitle,
    staffId: businessInfo.businessUserEmployeeId,
    storeQRCode: businessInfo.storeQRCodeFlag
  }

  panelTop = 0;
  isScroll = false;
  resetIsScroll = null;
  touchStartY = 0;
  touchStart(e) {
    this.touchStartY = e.touches[0].pageY;
  }

  touchMove(e) {
    // 内容滚动的时候touch无效
    if (this.isScroll) {
      return;
    }
    const y = e.touches[0].pageY;
    const diff = y - this.touchStartY;
    if (diff >= 30) {
      this.panelTop = this.originPanelTop;
    }
    if (diff <= -30) {
      this.panelTop = 0;
    }
  }

  handleSwipeChange(e) {
    if (e === 1 && this.tabs.length === 2) {
      this.activeTabKey = this.activeTabKey === 0 ? 2 : 0;
      return;
    }
    this.activeTabKey = e;
  }

  handleContentScroll(top) {
    this.isScroll = true;
    if (top >= this.originPanelTop) {
      this.panelTop = 0;
    } else {
      this.panelTop = this.originPanelTop;
    }
    this.resetIsScroll && this.resetIsScroll();
  }

  get originPanelTop() {
    return this.$refs.storeCardRef.clientHeight || 0;
  }

  get tabs() {
    return [
      { key: 0, label: '灵感', store: workListStore.all },
      businessInfo.data.businessType === BUSINESS_TYPE.CREATOR && { key: 1, label: '作品', store: workListStore.work }, // 只有创作者显示作品
      { key: 2, label: '介绍', store: introductionStore }
    ].filter(Boolean);
  }
  get activeTabIndex() {
    return this.tabs.findIndex(tab => tab.key === this.activeTabKey);
  }
  get currentTabStore() {
    return this.tabs[this.activeTabIndex].store;
  }
  @Watch('activeTabKey')
  async handleActiveTabKeyChange() {
    try {
      // url 上拼接字段、便于授权后跳转到指定 tab
      if (this.activeTabKey === 2) {
        // 切换到介绍的时候打开顶部卡片，有的介绍页不够滚动的条件
        this.panelTop = this.originPanelTop;
        const query = { ...this.baseQuery, operation: 'introduce' };
        const isLogin = await authStore.checkLogin(`${callbackUrl}${this.$router.resolve({ name: 'business-home', query }).href}`);
        if (!isLogin) { return; } // 查看授权状态
      }
      await this.currentTabStore.tryFetchData();
    } catch (e) {
      this.$showToast(e.message);
      Promise.reject(new Error(e.message));
    }
  }

  get secondTabs() {
    return [
      { key: 0, label: '全部', store: workListStore.all },
      { key: 1, label: '图片', store: workListStore.common },
      { key: 2, label: '视频', store: workListStore.video },
      { key: 3, label: '文章', store: workListStore.article },
    ];
  }
  get activeSecondTabIndex() {
    return this.secondTabs.findIndex(tab => tab.key === this.activeSecondTabKey);
  }
  get currentSecondTabStore() {
    return this.secondTabs[this.activeSecondTabIndex].store;
  }
  @Watch('activeSecondTabKey')
  async handleActiveSecondTabKeyChange() {
    await this.currentSecondTabStore.fetchData();
  }

  async mounted() {
    this.resetIsScroll = debounce(() => {
      this.isScroll = false;
    }, 100);
    try {
      if (!businessInfo.businessUserId) {
        const errMsg = '请携带有效的商家ID';
        this.$showToast(errMsg);
        return Promise.reject(new Error(errMsg));
      }

      this.code = this.$route.query.code;
      if (!authStore.data.headPic && this.code) {
        await authStore.login();
        this.$router.replace({ query: omit(this.$route.query, 'code') });
      }

      const operation = this.$route.query.operation;
      if (operation) {
        // 置空当前url中的operation，防止其它页面返回当前页面时再次触发跳转
        this.$router.replace({ query: omit(this.$route.query, 'operation') });
        if (operation === 'introduce') {
          this.activeTabKey = 2;
        } else if (operation === 'inquiry') {
          this.onHandleInquiry();
        }
      }

      const tab = this.$route.query.tab;
      const secondTab = this.$route.query.secondTab;
      tab && (this.activeTabKey = Number(tab));
      secondTab && (this.activeSecondTabKey = Number(secondTab));
    } catch (err) {
      this.$showToast(err.message);
      Promise.reject(new Error(err.message));
    }
  }

  async onFetch() {
    await Promise.all([
      businessInfo.tryFetchData(),
      this.currentSecondTabStore.tryFetchData(),
    ]);
    if (this.$route.query.showSaleInfo) {
      this.showSaleInfoPopup = true;
      this.$router.replace({ query: omit(this.$route.query, 'showSaleInfo') });
    }
    this.wxShare();
    await this.$nextTick();
    this.panelTop = this.originPanelTop;
  }

  // search 点击事件
  async handleSearchClick() {
    const query = {
      ...this.baseQuery,
      tab: this.activeTabKey,
      secondTab: this.activeSecondTabKey,
    };
    const callback = callbackUrl + this.$router.resolve({ name: 'search', query }).href;
    const isLogin = await authStore.checkLogin(callback);
    isLogin && this.$router.push({ name: 'search' });
  }

  async onHandleInquiry() {
    const callback = callbackUrl + this.$router.resolve({ name: 'business-home', query: { ...this.baseQuery, operation: 'inquiry' } }).href;
    const isLogin = await authStore.checkLogin(callback);
    if (!isLogin) { return; } // 查看授权状态

    privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.CLICK_CONTACT_INFORMATION }); // 触发 点击联系方式 埋点
    if (!businessInfo.data.contactInfos.length) {
      this.$showToast('暂无销售信息，敬请期待...');
      return;
    }

    try {
      if (businessInfo.data.haveContacted) { // 已提交客户信息
        this.showSaleInfoPopup = true;
        return;
      }
      if (authStore.data.phone) { // 有手机号 已授权
        await submitContact.fetchData();
        this.showSaleInfoPopup = true;
        return;
      }
      this.$router.push({ name: 'authorization', query: { type: 'home' } });
    } catch (err) {
      if (err.code === '40040') { // 重复提交联系方式
        this.showSaleInfoPopup = true;
        businessInfo.fetchData();
      } else {
        this.$showToast(err.message);
      }
      throw err;
    }
  }
  activated() {
    businessInfo.businessUserTitle && (document.title = businessInfo.businessUserTitle || businessInfo.data.name);
    this.$refs.ideasRef && this.$refs.ideasRef.waterfallUpdate();
    this.wxShare();
  }
}
</script>


<style lang="scss" scoped>
.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: $h5-max-width;
  height: 100vh;
  background-color: #fafafa;

  &> .content {
    position: relative;
    flex: 1;
  }

  .ai-enter {
    position: absolute;
    z-index: 10;
    top: 14px;
    right: 0;
    box-sizing: border-box;
    width: 69px;
    height: 30px;
    border-radius: 50px 0px 0px 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.28px;
    color: #fff;
    background: linear-gradient(88deg, #e5000e -2.59%, #fc9f09 81.42%);
  }
}

.store-card {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  max-width: $h5-max-width;
  padding: 12px;
  background-color: #fafafa;
}

.fixed-box {
  position: absolute;
  width: 100%;
  max-width: $h5-max-width;
  height: 100vh;
  overflow-y: hidden;
  transition: top 0.3s ease;

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    border-radius: 12px 12px 0 0;
    background-color: #fff;

    .tab-bar {
      background-color: #fafafa;
    }

    .content {
      flex: 1;
      height: 0;

      .my-swipe {
        height: 100%;

        .cmpt-ideas,
        .cmpt-works,
        .cmpt-presentations {
          height: 100%;
        }
      }
    }
  }
}

.inquiry-wrapper {
  position: absolute;
  z-index: 10;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: $h5-max-width;
  padding: 21px 16px;
}

.tool-btns {
  position: fixed;
  z-index: 10;
  bottom: 28%;
  right: calc((100vw - min(#{$h5-max-width}, 100vw)) / 2 + 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
}
</style>
