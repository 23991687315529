import { requestApp } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { businessInfo } from './business';

class PrivateDomainActionStore extends SimpleStore {
  async fetchData(params) {
    const res = await requestApp.post('/user/operation/privateDomainAction', { targetId: businessInfo.businessUserId, ...params });
    this.mergeFetched(res.data);
  }
}

export const privateDomainAction = new PrivateDomainActionStore();
