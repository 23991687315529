<template>
  <div class="component-action-sheet" :style="{ zIndex: zIndex }" @touchmove.stop="preventScroll">
    <div class="mask" v-if="value && mask" @click="handleClose" />
    <div class="wrapper" :class="{ active: value }" :style="{ maxHeight, background: bgColor, borderRadius }">
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

@Component
export default class ActionSheet extends Vue {
  @Prop({ type: String, default: '90vh' }) maxHeight
  @Prop({ type: String, default: '#fff' }) bgColor
  @Prop({ type: String, default: '15px 15px 0 0' }) borderRadius
  @Prop({ type: Boolean, default: true }) mask
  @Prop({ type: Number, default: 999 }) zIndex
  @Model('input', { type: Boolean, default: false }) value

  preventScroll() { /* 阻止滚动穿透 */ }

  @Emit('input')
  handleClose() {
    this.$emit('close', false);
    return false;
  }
}
</script>

<style lang="scss" scoped>
.component-action-sheet {
  position: relative;

  .mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: $h5-max-width;
    height: 100%;
    background: rgba(0, 0, 0, .48);
    transform: translateX(-50%);
  }

  .wrapper {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: $h5-max-width;
    overflow-y: scroll;
    background: #fff;
    transform: translate(-50%, 100%);
    transition: transform .3s;

    &.active {
      transform: translate(-50%, 0);
    }
  }

  .content {
    height: 100%;
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>
