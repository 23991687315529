<template>
  <ActionSheet :value="show" max-height="80vh" @close="$emit('update:show', false)">
    <div class="login-box">
      <div class="title">
        <img src="@/assets/icon-close-gary.png" alt="" class="icon icon-close" @click="$emit('update:show', false)" />
        <span class="text">登录</span>
      </div>
      <div class="business-info">
        <div class="avatar">
          <img
            :src="getSrc({ src: businessInfo.data.headPic, suffix: '?x-oss-process=image/resize,w_100,m_lfit/format,jpg' })"
            alt="" class="image" />
        </div>
        <div class="name">
          {{ businessInfo.data.name }}
        </div>
      </div>
      <div class="click-box">
        <div class="checkbox-label" @click="checked = !checked">
          <Checkbox :model-value="checked" />
          <span class="checkbox-text">
            我已阅读并同意<strong
              @click="$router.push({ name: 'agreement', query: { agreementName: '用户协议' } })">《用户协议》</strong>和<strong
              @click="$router.push({ name: 'agreement', query: { agreementName: '隐私协议' } })">《隐私政策》</strong>
          </span>
        </div>
        <button class="reset-btn submit-btn" @click="onSubmit">
          微信登录
        </button>
      </div>
    </div>
  </ActionSheet>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ActionSheet from '@/components/common/action-sheet.vue';
import { getSrc } from '@/utils';
import { businessInfo, authStore } from '@/store';
import Checkbox from '@/components/checkbox.vue';

@Component({
  components: { ActionSheet, Checkbox }
})
export default class LoginBoxPopup extends Vue {
  @Prop({ type: Boolean, default: false }) show
  @Prop({ type: String, default: '' }) callbackUrl

  businessInfo = businessInfo
  getSrc = getSrc
  checked = false

  async mounted() {
    await businessInfo.tryFetchData();
  }

  onSubmit() {
    if (!this.checked) {
      this.$toast('请先同意《用户协议》和《隐私政策》');
      return;
    }
    authStore.checkLogin(this.callbackUrl);
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  display: flex;
  flex-direction: column;
  gap: 72px;

  .title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0.32px;
    color: #1e1e1e;

    .icon-close {
      position: absolute;
      top: 14px;
      left: 12px;
      width: 22px;
      height: 22px;
    }
  }

  .business-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 1.6px;
    gap: 10px;

    .name {
      padding: 0 16px;
    }

    .avatar {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      overflow: hidden;

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .click-box {
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding: 12px;

    .submit-btn {
      padding: 16px;
      border-radius: 50px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      white-space: nowrap;
      letter-spacing: 0.72px;
      color: #fff;
      background: #4c4c4c;
    }

    .checkbox-label {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      color: #8c8c8c;
      gap: 6px;

      strong {
        color: #000;
      }
    }
  }
}
</style>
