<script>
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component
export default class Checkbox extends Vue {
  @Model('update:modelValue', { type: Boolean, default: false }) modelValue
  @Prop({ type: Boolean, default: false }) disabled

  toggleBase64 = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjg4NDU1MDA1NTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2ODQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiPjxwYXRoIGQ9Ik0xMDA0LjUwMzA0IDI2Ni44MjYyNGwtNTg3LjA3MiA1OTQuMTYwNjQtMTcuMTkwNCAxNi4xOTMyOC0xNy4xOTA0LTE2LjE5MzI4TDE5LjQ5Njk2IDQ5My4wMzgwOGwxMTguNTM2OTYtMTE5Ljk4NzIgMjYyLjE3ODU2IDI2NS4zNzk4NCA0ODUuNzUzNi00OTEuNjA5NkwxMDA0LjUwMzA0IDI2Ni44MjYyNCAxMDA0LjUwMzA0IDI2Ni44MjYyNHpNMTAwNC41MDMwNCAyNjYuODI2MjQiIGZpbGw9IiNGRkZGRkYiIHAtaWQ9IjI2ODUiPjwvcGF0aD48L3N2Zz4=';
}
</script>

<template>
  <div class="cmpt-checkbox" :class="{ checked: modelValue, disabled }"
    @click="$emit('update:modelValue', !modelValue)">
    <div class="checkmark-box">
      <div class="checkmark">
        <img class="checkmark-icon" :src="toggleBase64" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cmpt-checkbox {
  display: flex;
  align-items: center;

  .checkmark-box {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    border: 1px solid $black;
    border-radius: 50%;
  }

  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $black;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &.checked {
    .checkmark {
      opacity: 1;
    }

    .checkmark-icon {
      width: 80%;
      height: 80%;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    .checkmark-box {
      border-color: #babbbc;
    }

    .checkmark {
      background: #babbbc;
      opacity: 1;
    }
  }
}
</style>
