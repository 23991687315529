import { Vue } from 'vue-property-decorator';
import './styles/global.scss';
import router from './router';
import { authStore } from '@/store';
import _ from 'lodash';
import shareMixin from './mixins/share';
import './components';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

Vue.prototype.$get = _.get;
Vue.prototype.$camelCase = _.camelCase;

Vue.prototype.$authStore = Vue.observable(authStore);

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENV !== 'production') {
  const VConsole = require('vconsole');
  if (/Android|WindowsPhone|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent)) {
    new VConsole;
  }
}

Vue.mixin(shareMixin);

Vue.directive('enter-blur', { // 回车收起键盘
  inserted(el) {
    el.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') {
        el.blur();
      }
    });
  }
});

new Vue({
  router,
  data: {
    windowWidth: window.innerWidth
  },
  render: h => h(require('./app').default)
}).$mount('#app');
