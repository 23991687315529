<template>
  <div class="albums-page" @scroll="handleScroll" ref="albumsRef">
    <PageLoading :onFetch="onFetch" :forceLoading="pageLoading" />
    <div class="icon-back" @click="$router.push({ name: 'business-home' })">
      <div v-if="isShare" class="nav-home">店铺主页</div>
      <i v-else class="icon" v-html="IconBackSvg" />
    </div>
    <div class="title-wrapper">
      <div class="title">{{albumDetailStore.data.albumName}}</div>
      <div class="intro">{{albumDetailStore.data.intro}}</div>
    </div>
    <div class="user-info">
      <div class="user">
        <img :src="getSrc({src: albumDetailStore.data.logo})" alt="" class="image" />
        <span>{{albumDetailStore.data.shopName}}</span>
      </div>
      <div v-if="albumDetailStore.data.trendCount" class="ideas">灵感·{{ albumDetailStore.data.trendCount }}</div>
    </div>

    <div class="album-list">
      <InfiniteList :id="containerListId" @more="waterfallUpdate" :store="albumDetailStore">
        <Waterfall ref="waterfallRef" :options="{ gap:'0.58333rem', columns: 2 }">
          <WorkCard @imgLoad="waterfallUpdate" v-for="(item, index) in albumDetailStore.data?.trendIssueList?.list" :key="index" :card="item" class="item" />
        </Waterfall>
      </InfiniteList>
    </div>

    <div class="inquiry-wrapper">
      <Inquiry :head-src="getSrc({ src: businessInfo.data?.contactInfos?.[0]?.headImage })"
        @contact-click="onHandleInquiry" />
    </div>

    <div class="tool-btns">
      <ToolBtns :show-collection="false" />
    </div>

    <!-- 销售信息弹框 -->
    <ContactInfoPopup :show="showSaleInfoPopup" :contact-info="businessInfo.data.contactInfos" @update:show="showSaleInfoPopup = $event" />
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import PageLoading from '@/components/page-loading.vue';
import { businessInfo, authStore, submitContact, privateDomainAction, AlbumDetailStore } from '@/store';
import WorkCard from '@/components/work-card.vue';
import InfiniteList from '@/components/infinite-list.vue';
import Waterfall from 'easy-waterfall-2/dist/waterfallVue';
import { throttle, omit } from 'lodash';
import { getSrc } from '@/utils';
import { DOMAIN_ACTION, callbackUrl } from '@/constants/index';
import Inquiry from '@/components/inquiry.vue';
import ContactInfoPopup from '@/components/contact-info-popup.vue';
import ToolBtns from '@/views/business/detail/components/tool-btns.vue';
import { previousRoute } from '@/router';
import IconBackSvg from '!raw-loader!@/assets/icon-back.svg';

@Component({
  name: 'Albums',
  components: { PageLoading, WorkCard, InfiniteList, Waterfall, Inquiry, ContactInfoPopup, ToolBtns }
})
export default class Albums extends Vue {
  albumDetailStore = {};
  businessInfo = businessInfo
  getSrc = getSrc

  showSaleInfoPopup = false
  containerListId = 'AlbumListId'
  contentScrollTop = 0
  pageLoading = false
  IconBackSvg = IconBackSvg

  handleScroll() {
    this.contentScrollTop = this.$refs.albumsRef.scrollTop;
  }

  async activated() {
    if (previousRoute.value.path !== '/') {
      this.$refs.albumsRef.scrollTop = this.contentScrollTop;
    } else {
      this.pageLoading = true;
      await this.init();
      this.pageLoading = false;
    }
  }

  waterfallUpdate = throttle(() => {
    if (this.$refs.waterfallRef) {
      this.$refs.waterfallRef.update();
    }
  }, 10);

  get albumsId() {
    return this.$route.params.id;
  }

  get isShare() {
    return this.$route.query.isShare;
  }

  async init() {
    this.albumDetailStore = new AlbumDetailStore(this.albumsId);
    await Promise.all([
      this.albumDetailStore.fetchData(),
      businessInfo.tryFetchData(),
      authStore.tryFetchData()
    ]);
    this.wxShare({ shareParams: {
      link: `${location.origin}${this.$router.resolve({ name: 'albums', params: { id: this.albumsId }, query: { isShare: 1 } }).href}`,
      title: `${businessInfo.data.name}的专辑，${this.albumDetailStore.data.albumName}`,
      timelineTitle: `${businessInfo.data.name}的专辑，${this.albumDetailStore.data.albumName}`,
      desc: this.albumDetailStore.data.intro
    } });
  }

  async onFetch() {
    this.pageLoading = true;
    this.code = this.$route.query.code;
    if (this.code) {
      await authStore.login();
      this.$router.replace({ query: omit(this.$route.query, 'code') });
    }

    try {
      await authStore.tryFetchData();
    } catch (err) {
      if ([20004, 20005].includes(Number(err.code))) {
        const callback = callbackUrl + this.$router.resolve({ name: 'albums' }).href;
        return await authStore.checkLogin(callback);
      }
    }

    await this.init();
    this.pageLoading = false;

    if (this.$route.query.showSaleInfo) {
      this.showSaleInfoPopup = true;
      this.$router.replace({ query: omit(this.$route.query, 'showSaleInfo') });
    }
  }

  async onHandleInquiry() {
    privateDomainAction.fetchData({ actionType: DOMAIN_ACTION.CLICK_CONTACT_INFORMATION }); // 触发 点击联系方式 埋点
    if (!businessInfo.data.contactInfos.length) {
      this.$showToast('暂无销售信息，敬请期待...');
      return;
    }

    try {
      if (businessInfo.data.haveContacted) { // 已提交客户信息
        this.showSaleInfoPopup = true;
        return;
      }
      if (authStore.data.phone) { // 有手机号
        await submitContact.fetchData();
        this.showSaleInfoPopup = true;
        return;
      }
      this.$router.push({ name: 'authorization', query: { type: 'albums', id: this.albumsId } });
    } catch (err) {
      if (err.code === '40040') { // 重复提交联系方式
        this.showSaleInfoPopup = true;
        businessInfo.fetchData();
      } else {
        this.$showToast(err.message);
      }
      throw err;
    }
  }
}
</script>


<style lang="scss" scoped>
.albums-page {
  position: relative;
  align-items: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 0 10px env(safe-area-inset-bottom);
  overflow: scroll;

  .icon-back {
    display: flex;
    align-items: center;
    height: 56px;

    .icon {
      width: 36px;
      height: 36px;
      margin-left: 2px;
      object-fit: cover;
    }

    .nav-home {
      flex-shrink: 0;
      width: 69px;
      height: 30px;
      margin-left: -10px;
      border-radius: 0px 50px 50px 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.28px;
      color: #fff;
      background: linear-gradient(88deg, #e5000e -2.59%, #fc9f09 81.42%);
    }
  }

  .title-wrapper {
    padding: 12px 6px;
    line-height: 22px;
    color: #1e1e1e;

    .title {
      font-size: 20px;
      letter-spacing: 0.4px;
    }

    .intro {
      margin-top: 14px;
      font-size: 14px;
      letter-spacing: 0.28px;
    }
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    padding: 31px 6px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1e1e1e;
    gap: 12px;

    .user {
      display: flex;
      align-items: center;
      gap: 6px;

      .image {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ideas {
      white-space: nowrap;
    }
  }

  .album-list {
    width: 100%;
    padding-bottom: 92px;
    margin-top: 12px;
  }

  .inquiry-wrapper {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 50%;
    box-sizing: border-box;
    width: 100%;
    max-width: $h5-max-width;
    padding: 21px 16px;
    transform: translateX(-50%);
  }
}

.tool-btns {
  position: fixed;
  z-index: 10;
  bottom: 19%;
  right: calc((100vw - min(#{$h5-max-width}, 100vw)) / 2 + 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
}
</style>
