<template>
  <div class="page-store-environment">
    <v-title value="店铺环境" />
    <div class="nav" @click="$router.go(-1)">
      <span class="icon-arrow"></span>
      <div class="text">
        店铺美图（{{ imgList.length }}）
      </div>
    </div>
    <div class="image-list">
      <div v-for="(src, index) in imgList" :key="index" class="image-wrapper" @click="previewImages(index)">
        <img :src="src" alt="" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import wx from 'weixin-js-sdk';
import { getSrc } from '@/utils';

@Component
export default class StoreEnvironment extends Vue {
  get imgList() {
    const data = this.$route.query.data;
    if (Array.isArray(data)) {
      return data.map(src => getSrc({ src, isAvatar: false }));
    } else {
      const errMsg = '数据格式出错';
      this.$toast(errMsg);
      return Promise.reject(new Error(errMsg));
    }
  }

  previewImages(index) {
    wx.previewImage({
      current: this.imgList[index],
      urls: this.imgList,
    });
  }

  mounted() {
    this.wxShare({ canShare: false });
  }
}
</script>

<style lang="scss" scoped>
.page-store-environment {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;

  .nav {
    display: flex;
    align-items: center;
    padding: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.28px;
    color: #1e1e1e;
    gap: 2px;

    .icon-arrow {
      width: 8px;
      height: 8px;
      margin: 6px;
      border: 2px solid #1e1e1e;
      border-top: none;
      border-right: none;
      transform: rotate(45deg);
    }
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 0 12px 12px;

    .image-wrapper {
      position: relative;
      padding-bottom: 170px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f0f0f0;

      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
