<template>
  <div class="search-box">
    <img src="@/assets/icon-arrow-black.png" @click="navBack" class="icon-black" />
    <div class="search-content">
      <img src="@/assets/icon-search.png" alt="" class="icon" />
      <input :value="modelValue" type="text" placeholder="搜索" class="reset-input input flex-1" @input="onHandleInput"
        @keyup.enter="$emit('search', modelValue)" @focus="$emit('focus')" v-enter-blur />
      <img v-if="modelValue" src="@/assets/icon-close-gary.png" alt="" class="icon icon-close"
        @click="$emit('cancel')" />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Model } from 'vue-property-decorator';

@Component
export default class SearchBox extends Vue {
  @Model('update:modelValue', { type: String, default: '' }) modelValue;

  get text() {
    return this.modelValue;
  }

  onHandleInput(e) {
    this.$emit('change', e.target.value);
    this.$emit('update:modelValue', e.target.value);
  }

  navBack() {
    this.$emit('update:modelValue', '');
    this.$emit('back');
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  gap: 12px;

  .icon-black {
    width: 15px;
    height: 15px;
  }

  .search-content {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 10px 12px;
    border-radius: 12px;
    background-color: #f5f5f5;
    gap: 6px;

    .icon {
      width: 17px;
      height: 17px;

      &.icon-close {
        width: 20px;
        height: 22px;
      }
    }

    .input {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: $primary-color;
      background-color: transparent;
    }
  }
}
</style>
