// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/home
import { Vue } from 'vue-property-decorator';
import {
  Button,
  Toast,
  List,
  Loading,
  Icon,
  Swipe,
  SwipeItem,
  Popup,
} from 'vant';

Vue
  .use(Button)
  .use(Loading)
  .use(List)
  .use(Icon)
  .use(Swipe)
  .use(SwipeItem)
  .use(Popup);

Vue.prototype.$showToast = Toast;
