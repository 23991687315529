<template>
  <div v-if="show" style="padding-top: var(--progress-padding-top, 0);" class="create-loading">
    <ProgressView :value="progressVal"/>
    <div class="tips">请遵守《生成式人工智能服务管理办法》</div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import ProgressView from '@/components/progress-view.vue';
  import { requestAi } from '@/utils';

  @Component({
    components: {
      ProgressView
    }
  })
  export default class CreateLoading extends Vue {
    @Prop() code;

    @Watch('code', { immediate: true })
    codeChanged(val, old) {
      if (val && old !== val) {
        this.start();
      }
    }

    progressVal = 0;
    show = false
    timer = null;

    start() {
      this.show = true;
      this.progressVal = 0;
      this.handleCheckState();
      clearInterval(this.timer);
      this.progressIncrease();
    }

    progressIncrease() {
      // 刚开始进度要快一点，后面进度要慢一点
      const step = this.progressVal < 80 ? 4 : 10;
      const time = this.progressVal * step + 50;
      this.timer = setTimeout(() => {
        if (this.progressVal < 99) {
          this.progressVal++;
          this.progressIncrease();
        } else {
          clearInterval(this.timer);
        }
      }, time);
    }

    async handleCheckState() {
      const { data } = await requestAi.get(`images/${this.code}`);
      if (data.state === 'completed') {
        this.progressVal = 100;
        setTimeout(() => {
          // 等待动画结束
          this.show = false;
          this.$emit('success', data);
        }, 400);
      } else if (data.state === 'failed') {
        await this.$alert('生成失败，请重试');
        this.show = false;
        this.$emit('failed');
      } else {
        setTimeout(() => {
          this.handleCheckState();
        }, 2000);
      }
    }
  }
</script>

<style lang="scss" scoped>
.create-loading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .tips {
    padding: 50px 0 20px;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: #aeaeae;
  }
}

.progress-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 47px;

  .title {
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.36px;
    color: transparent;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    -webkit-background-clip: text; /* stylelint-disable-line */
  }

  .gif {
    --progressing-size: 70px;
    margin-top: 31px;
  }

  .num {
    margin-top: 17px;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: 0.48px;
    color: transparent;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    -webkit-background-clip: text; /* stylelint-disable-line */
  }
}

.bar-box {
  position: relative;
  width: 100%;
  margin-top: 17px;
  border-radius: 16px;
  overflow: hidden;

  .bar {
    height: 6px;
  }

  .total {
    background: rgba(250, 148, 9, 0.30);
  }

  .progressing {
    position: absolute;
    top: 0;
    left: 0;
    background: #fb9a09;
    transition: width 0.3s;
  }
}
</style>
