<template>
  <!-- TODO -->
  <div class="page">
    好看鸭技术支持介绍长图页
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class StoreEnvironment extends Vue {
  mounted() {
    this.wxShare();
  }
}
</script>
