// 获取手机浏览器高度，使用 100vh 会包含导航栏
export function getBrowserInterfaceSize() {
  let pageWidth = window.innerWidth;
  let pageHeight = window.innerHeight;

  if (typeof pageWidth !== 'number') {
    //在标准模式下面
    if (document.compatMode === 'CSS1Compat') {
      pageWidth = document.documentElement.clientWidth;
      pageHeight = document.documentElement.clientHeight;
    } else {
      pageWidth = document.body.clientWidth;
      pageHeight = window.body.clientHeight;
    }
  }

  return {
    pageWidth: pageWidth,
    pageHeight: pageHeight
  };
}
