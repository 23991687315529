import _, { lowerCase } from 'lodash';
import qs from 'qs';
import axios from 'axios';
import { decoder } from './decoder';
import { authStore } from '@/store';
import createLoadingComponent from './loading';
import { getUrlCode } from '@/utils';

const loadingData = {
  maxCount: 0,
  loadingInstance: null,
  loadingSet: new Set(),
};

let login_once = _.once(login);
async function login() {
  try {
    await authStore.login();
  } finally {
    login_once = _.once(login);
  }
}

export const requestUser = getAxiosInstance({
  baseURL: lowerCase(process.env.VUE_APP_ENV) === 'development' ? process.env.VUE_APP_LOCAL_API : `${process.env.VUE_APP_WEB_API}/api/user`,
});
export const requestApp = getAxiosInstance({
  baseURL: `${process.env.VUE_APP_WEB_API}/api/app`,
});
export const requestAi = getAxiosInstance({
  baseURL: `${process.env.VUE_APP_WEB_API_AI}/app_api/v1`,
});

function getAxiosInstance(defaults) {
  const axiosInstance = axios.create({
    timeout: 30000,
    headers: { 'Content-Type': 'application/json' },
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    },
    ...defaults,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    if (config.headers && !config.headers.Authorization) {
      const isAiRequest = config.baseURL.includes('/app_api/v1');
      config.headers.Authorization = isAiRequest ? (authStore.access_token?.split('Bearer ')[1] || '') :  authStore.access_token;
    }
    if (config.headers && !config.headers.Platform) {
      config.headers.Platform = 'h5';
    }
    handleLoadingStart(config);
    return config;
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      if (res.config.url !== 'my/myInfo' && (res.data.code === '20004' || res.data.code === '20005')) { // 未登录或 token 过期
        return login_once().then(() => {
          return new Promise((resolve) => {
            const code = getUrlCode('code');
            if (code) {
              resolve(requestUser.request(res.config));
            } else {
              resolve(res);
            }
            handleLoadingStop(res.config);
          });
        });
      }

      if (res.data.code > 299 || res.data.code < 200) {
        handleLoadingStop(res.config);
        const { data: errInfo } = res;
        const err = { message: errInfo.message, code: errInfo.code, status: errInfo.code };
        showError(res);
        return Promise.reject(err);
      }

      res.isResponse = true;
      res.meta = {};
      handleLoadingStop(res.config);

      _.forEach(res.headers, (v, k) => {
        if (/^x-/i.test(k)) {
          const key
          = _.snakeCase(k.replace(/^x-/i, ''));
          res.meta[key] = decoder(v);
        }
      });

      return res;
    },
    async (err) => {
      const response = _.get(err, 'response', {});
      handleLoadingStop(err.config);
      if (response.data) {
        const { error_message, messages, error, code } = response.data;
        err.message = error_message || messages || error || err.message;
        err.code = code;
      }

      err.status = response.status;
      if (response.status === 401) {
        _.set(err.config, 'headers[Authorization]', '');
        return Promise.reject(err);
      }
      showError(err);
      return Promise.reject(err);
    },
  );

  return axiosInstance;
}

function showError(err) {
  const { showError } = getLoadingConfig(err.config?.loading);

  if (showError) {
    // TODO 显示错误信息
    // Toast.fail('Request error');
  }
}

function getLoadingConfig(loading) {
  if (loading === false) {
    return {
      showLoading: false,
      showError: false,
      showMask: false,
    };
  }
  return Object.assign({
    showLoading: true,
    showError: true,
    showMask: true,
  }, loading);
}

function handleLoadingStart(config) {
  const { showMask, showLoading } = getLoadingConfig(config?.loading);
  if (!showLoading) {
    return;
  }
  const { loadingSet } = loadingData;
  loadingSet.add(config);
  loadingData.maxCount++;
  loadingData.loadingInstance = createLoadingComponent({
    value: loadingSet.size,
    max: loadingData.maxCount + 1,
    showMask,
  });
}

function handleLoadingStop(config) {
  const { showLoading } = getLoadingConfig(config?.loading);
  if (!showLoading) {
    return;
  }
  // TODO: 报错了，先注释掉
  // const { loadingSet } = loadingData;
  // if (loadingSet.has(config)) {
  //   loadingSet.delete(config);
  //   loadingData.loadingInstance.data.value = loadingData.maxCount - loadingSet.size + 1;
  // }
  // if (loadingSet.size === 0) {
  //   loadingData.loadingInstance.data.visible = false;
  //   loadingData.maxCount = 0;
  // }
}
