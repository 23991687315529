import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=5abea67a&scoped=true"
import script from "./checkbox.vue?vue&type=script&lang=js"
export * from "./checkbox.vue?vue&type=script&lang=js"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=5abea67a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_4rliqqz3ui7xo7iaw2ukiguuga/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5abea67a",
  null
  
)

export default component.exports