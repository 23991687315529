<template>
  <div class="progressing-wrapper">
    <img src="@/assets/progressing-0.png" class="image-bg">
    <img src="@/assets/progressing-1.png" class="image">
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class Progressing extends Vue {}
</script>

<style lang="scss" scoped>
.progressing-wrapper {
  position: relative;
  display: inline-block;
  width: var(--progressing-size, 50px);
  height: var(--progressing-size, 50px);
  vertical-align: middle;

  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    animation: progressing-rotate 1.5s linear infinite;
  }

  // 旋转
  @keyframes progressing-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
