import { requestApp } from '@/utils';
import { SimpleStore } from './helper/simple-store';
import { businessInfo } from '@/store';
import { Collection } from './helper/collection';

export class Albums extends SimpleStore {
  async fetchData() {
    await this.mergeFetched(requestApp.post('/album/privateDomainList', { businessUserId: businessInfo.businessUserId }));
  }
}
export const albums = new Albums();

export class AlbumDetailStore extends Collection {
  id = null;
  constructor(id) {
    super();
    this.id = id;
  }

  static defaultParams = {
    pageSize: 39,
    pageIndex: 1,
  }

  async fetch() {
    return await requestApp.get(`/album/record/trend/${this.id}/${this.params.pageIndex}/${this.params.pageSize}`);
  }

  async fetchMoreData() {
    this.params.pageIndex++;
    const { data } = await this.fetch();
    this.data.trendIssueList?.list?.push(...data.data?.trendIssueList?.list);
  }

  get isComplete() {
    return (this.data.trendCount || 0) <= this.params.pageIndex * this.params.pageSize;
  }

  get isEmpty() {
    return this.data.trendIssueList?.list?.length === 0;
  }
}
