<template>
  <div class="cmpt-works-wrapper" @scroll="handleScroll" ref="worksRef">
    <div class="cmpt-works">
      <InfiniteList :store="store">
        <div class="work-list">
          <div v-for="(item, index) in store.data" :key="index" class="work"
            @click="handleClick(item.trendIssueId, item.title)">
            <div class="image-wrapper">
              <img :src="getSrc({ src: item.coverUri, isAvatar: false })" alt="" class="image" />
            </div>
            <div class="text text-overflow">
              {{ item.title }}
            </div>
            <div class="tabs-wrapper">
              <div class="tabs">
                <div v-for="(tab, tabIndex) in item.tabs" :key="tab.id || tabIndex" class="tab">
                  {{ tab.tabName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </InfiniteList>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import WorkCard from '@/components/work-card.vue';
import InfiniteList from '@/components/infinite-list.vue';
import { authStore, businessInfo } from '@/store';
import { callbackUrl } from '@/constants/index';
import { getSrc } from '@/utils';

@Component({
  components: { WorkCard, InfiniteList },
})
export default class Works extends Vue {
  @Prop({ type: Object, required: true, default: () => ({}) }) store;g
  @Prop({ type: Number }) tab;

  getSrc = getSrc
  contentScrollTop = 0

  handleScroll() {
    this.contentScrollTop = this.$refs.worksRef.scrollTop;
    this.$emit('scroll', this.contentScrollTop);
  }
  async activated() {
    await this.$nextTick();
    this.$refs.worksRef.scrollTop = this.contentScrollTop;
  }

  async handleClick(id, title) {
    const query = {
      businessId: businessInfo.businessUserId,
      id,
      tab: this.tab,
      title: businessInfo.businessUserTitle,
      staffId: businessInfo.businessUserEmployeeId,
      storeQRCode: businessInfo.storeQRCodeFlag
    };
    const callback = callbackUrl + this.$router.resolve({ name: 'label-detail', query }).href;
    const isLogin = await authStore.checkLogin(callback);
    if (!isLogin) { return; }
    this.$router.push({ name: 'label-detail', query: { id, tab: this.tab, title } });
  }
}
</script>

<style lang="scss" scoped>
.cmpt-works-wrapper {
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  overflow-y: scroll;
}

.cmpt-works {
  padding: 4px 12px 92px;

  .work-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .work {
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 6px 0px rgba(136, 136, 136, 0.14);
    gap: 12px;

    .image-wrapper {
      position: relative;
      padding-bottom: 240px;
      background-color: #f0f0f0;

      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      padding: 0 12px;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.36px;
      color: $primary-color;
    }

    .tabs-wrapper {
      padding: 0 12px;

      .tabs {
        display: flex;
        padding-bottom: 12px;
        overflow-x: scroll;
        gap: 8px;

        &::-webkit-scrollbar {
          display: none;//隐藏滚动条
        }

        .tab {
          padding: 5px 14px;
          border-radius: 35px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          letter-spacing: 0.72px;
          color: #6b6b6b;
          background: #f0f0f0;
        }
      }
    }
  }
}
</style>
