<template>
  <div class="page-agreement">
    <v-title :value="name" />
    <PageLoading :onFetch="onFetch" />

    <div v-html="content.agreementContents"></div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { agreements } from '@/store';
import PageLoading from '@/components/page-loading.vue';

@Component({
  components: { PageLoading }
})
export default class Agreement extends Vue {
  agreements = agreements;
  content = '';
  name = this.$route.query.agreementName || '用户协议';

  mounted() {
    if (this.name && typeof this.name !== 'string') {
      const errMsg = '数据格式错误';
      this.$showToast(errMsg);
      return Promise.reject(new Error(errMsg));
    }
    this.wxShare({ canShare: false });
  }

  async onFetch() {
    await agreements.tryFetchData();
    this.content = agreements.data?.find(item => item.agreementName === this.name);
  }
}
</script>


<style lang="scss" scoped>
.page-agreement {
  height: 100vh;
  padding: 0 10px env(safe-area-inset-bottom);
}
</style>
