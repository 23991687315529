<template>
  <div ref="component" :style="[style]" class="bean-button-fixed-bottom">
    <div class="fixed-footer" :style="[style, { background: bgColor, zIndex }]">
      <div class="content" :class="[componentClass]"><slot/></div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ButtonFixedBottom extends Vue {
    @Prop({ type: String, default: '#fff' }) bgColor
    @Prop({ type: Number, default: 99 }) zIndex
    contentHeight = 0

    get componentClass() {
      return `component-fixed-button-${this._uid}`;
    }

    get style() {
      const height = `${this.contentHeight}px`;
      return { height, boxSizing: 'content-box' };
    }

    mounted() {
      this.run();
    }

    run() {
      const ele = this.$refs.component;
      const { height } = ele.querySelector(`.${this.componentClass}`).getBoundingClientRect();
      this.contentHeight = height || 0;
    }

    async update() {
      await this.$nextTick();
      this.run();
    }
  }
</script>

<style lang="scss">
  .bean-button-fixed-bottom {
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);

    .fixed-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: $h5-max-width;
      padding-bottom: env(safe-area-inset-bottom);
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    }
  }
</style>
