<template>
  <ActionSheet :value="show" max-height="100vh" @close="$emit('update:show', false)">
    <div class="employee">
      <div class="sale-info-box" @click.stop>
        <div class="avatar-box">
          <div class="avatar">
            <img :src="getSrc({ src: saleInfo?.headImage })" alt="" class="image" />
          </div>
          <div class="info">
            <div v-if="saleInfo?.contactName" class="name">
              {{ saleInfo?.contactName }}
            </div>
            <div v-if="saleInfo?.position" class="position">
              {{ saleInfo?.position }}
            </div>
          </div>
        </div>
        <div v-if="saleInfo?.contactsLandline || saleInfo?.contactsPhone || saleInfo?.wechatId"
          class="form-box">
          <a v-if="saleInfo?.contactsLandline" class="field" :href="`tel:${saleInfo?.contactsLandline}`">
            <span class="label">座机：{{ saleInfo?.contactsLandline }}</span>
            <span class="icon-arrow"></span>
          </a>
          <a v-if="saleInfo?.contactsPhone" class="field" :href="`tel:${saleInfo?.contactsPhone}`">
            <span class="label">手机：{{ saleInfo?.contactsPhone }}</span>
            <span class="icon-arrow"></span>
          </a>
          <div v-if="saleInfo?.wechatId" class="field" @click="copy({text: saleInfo?.wechatId, successWriter:'复制成功\n去微信添加好友'})">
            <span class="label">微信：{{ saleInfo?.wechatId }}</span>
            <span class="icon-arrow"></span>
          </div>
        </div>
        <div v-else class="empty-contact">暂无联系方式</div>
      </div>
    </div>
    <div class="reset-btn btn-cancel" @click="$emit('update:show', false)">
      取消
    </div>
  </ActionSheet>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ActionSheet from '@/components/common/action-sheet.vue';
import { copy, getSrc } from '@/utils';

@Component({
  components: { ActionSheet }
})
export default class ContactInfoDetailPopup extends Vue {
  @Prop({ type: Boolean, default: false }) show
  @Prop({ type: Object, default: () => ({}) }) saleInfo

  copy = copy
  getSrc = getSrc
}
</script>

<style scoped lang="scss">

  .box-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 80vh;
  }

  .sale-info-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    max-width: 778px;
    height: 100%;
    padding: 18px 0 0;
    font-size: 16px;
    color: $primary-color;

    .avatar-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatar {
        width: 145px;
        height: 145px;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        overflow: hidden;

        .image {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 18px;
        font-size: 16px;
        gap: 10px;

        .name {
          font-weight: 600;
        }

        .position {
          color: #8c8c8c;
        }
      }
    }

    .form-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 26px;

      .field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        height: 50px;
        padding: 0 20px;
        border-bottom: 1px solid #f0f0f0;
        font-weight: 400;
        text-decoration: none;
        letter-spacing: 0.32px;
        color: $primary-color;

        .icon-arrow {
          box-sizing: border-box;
          width: 6px;
          height: 6px;
          margin: 10px;
          border: 1px solid #8c8c8c;
          border-bottom: none;
          border-left: none;
          transform: rotate(45deg);
        }
      }
    }

    .empty-contact {
      line-height: 150px;
      text-align: center;
      color: #8c8c8c;
    }
  }

  .btn-cancel {
    padding: 20px 0;
    font-weight: 350;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.32px;
  }
</style>
