<template>
  <div class="page-login">
    <PageLoading :onFetch="onFetch" />

    <div class="contain">
      <img src="@/assets/icon-arrow-left.png" alt="" class="icon-back" @click="$router.go(-1)" />

      <div class="header" :style="[headerStyle]">
        <div class="info">
          <div class="avatar">
            <img
              :src="getSrc({ src: businessInfo.data.headPic, suffix: '?x-oss-process=image/resize,w_100,m_lfit/format,jpg' })"
              alt="" class="image" />
          </div>
          <div class="name">
            {{ businessInfo.data.name }}
          </div>
        </div>
        <div class="introduction-list">
          <span class="item" v-for="(item, index) in introductions" :key="index">{{ item }}</span>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="content-box">
          <div class="title">
            <div>马上联系,获取专属<span class="high-light">优惠!</span></div>
          </div>

          <div class="form-box">
            <div class="field" @click="showActionSheet = true">
              <div class="label">
                头像
              </div>
              <div class="avatar flex-1">
                <button class="reset-btn avatar-btn">
                  <img :src="form.avatar || randomAvatar" alt="" class="image" />
                </button>
              </div>
              <span class="icon-arrow"></span>
            </div>

            <div class="field">
              <div class="label">
                昵称
              </div>
              <input v-model="form.name" placeholder="昵称" class="reset-input input" v-enter-blur />
            </div>

            <div class="field">
              <div class="label">
                手机号
              </div>
              <input v-if="!authStore.data.phone" v-model="form.phone" placeholder="手机号" class="reset-input input" v-enter-blur />
              <div v-else class="reset-input input">
                {{ authStore.data.phone }}
              </div>
            </div>

            <div v-if="!authStore.data.phone" class="field">
              <div class="label">
                验证码
              </div>
              <input v-model="verifyCode" placeholder="" class="reset-input input flex-1" @input="verifyCodeChange" v-enter-blur />
              <div v-if="countDown > 0" class="reset-btn send-verify">
                {{ `${countDown}s后重新发送` }}
              </div>
              <button v-else class="reset-btn send-verify" @click="onHandleSendVerifyCode">
                发送验证码
              </button>
            </div>
          </div>

          <div class="click-box">
            <div class="checkbox-label" @click="checked = !checked">
              <Checkbox :model-value="checked" />
              <span class="checkbox-text">
                我已阅读并同意<strong
                  @click="$router.push({ name: 'agreement', query: { agreementName: '用户协议' } })">《用户协议》</strong>和<strong
                  @click="$router.push({ name: 'agreement', query: { agreementName: '隐私协议' } })">《隐私政策》</strong>
              </span>
            </div>
            <button class="reset-btn submit-btn" :class="{disabled: submitBtnDisabled}" @click="onSubmit">
              立即获取销售联系方式
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" v-if="loading">
      <van-loading size="2rem" vertical>上传中...</van-loading>
    </div>

    <ActionSheet :value="showActionSheet" @close="showActionSheet = false">
      <div class="action-content" @click.stop>
        <div class="action">
          拍照上传
          <input type="file" accept="image/png,image/jpg" capture="camera" class="upload-input" @change="handleUpload">
        </div>
        <div class="action">
          相册选图
          <input type="file" accept="image/*" class="upload-input" @change="handleUpload">
        </div>
        <div class="action" @click="showActionSheet = false">
          取消
        </div>
      </div>
    </ActionSheet>

    <div class="cropper-wrapper" :style="{ height: getContainerHeight + 'px' }" v-show="showCropper">
      <div class="cropper-box">
        <VueCropper
          ref="cropper"
          outputType="png"
          :img="tempAvatar"
          :autoCrop="true"
          :fixedNumber="[1, 1]"
          :fixed="true"
          :canMove="false"
          :centerBox="true"
          :fixedBox="false"
          style="background: #000"
        />
      </div>

      <div class="btns">
        <button class="reset-btn btn" @click="showCropper = false">取消</button>
        <button class="reset-btn btn" @click="handleCropper">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { businessInfo, authStore, submitContact } from '@/store';
import defaultAvatar from '@/assets/head.svg';
import { requestUser, getSrc, sleep, requestApp, getBrowserInterfaceSize, toFormData } from '@/utils';
import Checkbox from '@/components/checkbox.vue';
import PageLoading from '@/components/page-loading.vue';
import ActionSheet from '@/components/common/action-sheet.vue';
import { VueCropper } from 'vue-cropper';
import { DOMAIN_OSS } from '@/constants/index';

@Component({
  components: { Checkbox, PageLoading, ActionSheet, VueCropper }
})
export default class Authorization extends Vue {
  businessInfo = businessInfo
  authStore = authStore
  getSrc = getSrc
  defaultAvatar = defaultAvatar
  countDown = 0
  form = {
    name: '',
    phone: '',
    avatar: '',
  }
  verifyCode = ''
  checked = false
  timer = null
  showActionSheet = false
  loading = false
  showCropper = false
  tempAvatar = ''
  submitBtnDisabled = false

  introductions = ['线下实体店', '销售1对1', '专属优惠', '线上展览', 'AI自动设计']

  get randomAvatar() {
    const random = Math.floor(Math.random() * (6)) + 1;
    return `${DOMAIN_OSS}init/defaulthead/hs${random}.png`;
  }

  mounted() {
    this.wxShare({ canShare: false });
  }

  async onFetch() {
    this.form.phone = authStore.data.phone || '';
    this.form.name = authStore.data.name || '';
    this.form.avatar = getSrc({ src: authStore.data.headPic, isAvatar: false });
  }

  async onHandleSendVerifyCode() {
    if (!this.form.phone) {
      this.$showToast('请输入手机号');
      return;
    }

    const regex = /^1[3456789]\d{9}$/;
    if (!regex.test(this.form.phone)) {
      this.$showToast('号码格式有误');
      return;
    }

    try {
      await requestUser.get(`/business/getCode?type=h5&phone=${this.form.phone}`);
      this.$showToast('发送成功');
      this.timer && clearInterval(this.timer);
      this.countDown = 60;
      this.timer = setInterval(() => {
        if (this.countDown <= 0) {
          clearInterval(this.timer);
          return;
        }
        this.countDown -= 1;
      }, 1000);
    } catch (e) {
      this.$showToast(e.message);
    }
  }

  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  }

  validateForm() {
    const { name, phone } = this.form;
    const errors = [];
    if (!name) {
      errors.push('请填写昵称');
    }
    if (!this.checked) {
      errors.push('请先同意《用户协议》和《隐私政策》');
    }
    if (!authStore.data.phone) { // 未绑定手机号
      if (!phone) {
        errors.push('请填写手机号');
      }
      if (!this.verifyCode) {
        errors.push('请填写验证码');
      }
    }
    if (errors.length === 0) {
      return Promise.resolve();
    }
    const errMsg = errors[0];
    return Promise.reject(new Error(errMsg));
  }

  get prePageName() {
    switch (this.$route.query.type) {
      case 'detail':
       return 'label-detail';
      case 'albums':
        return 'albums';
      default:
        return 'business-home';
    }
  }
  async onSubmit() {
    if (this.submitBtnDisabled) {
      return;
    }
    const { name, phone, avatar } = this.form;
    this.submitBtnDisabled = true;
    try {
      await this.validateForm();
      this.$showToast('已提交，请稍等');
      if (!authStore.data.phone) { // 未绑定手机号
        await requestUser.post('/h5/checkSmsCode', { phone, code: this.verifyCode, userId: authStore.data.userId });
      }
      await submitContact.fetchData(); // 提交客户信息
      await authStore.updateUserInfo({ name, headImage: avatar || this.randomAvatar });
      this.$showToast('提交成功');
      await sleep(1000);
      if (this.prePageName === 'albums') {
        this.$router.push({ name: this.prePageName, query: { showSaleInfo: 1 }, params: { id: this.$route.query.id } });
      } else {
        this.$router.push({ name: this.prePageName, query: { showSaleInfo: 1, id: this.$route.query.id } });
      }
    } catch (e) {
      this.submitBtnDisabled = false;
      this.$showToast(e.message);
      throw e;
    }
  }

  verifyCodeChange(e) {
    const { value } = e.target;
    if (value.length > 4) {
      this.verifyCode = value.slice(0, 4);
    }
  }

  async handleUpload(e) {
    const file = e.target.files[0];
    this.showActionSheet = false;
    this.tempAvatar = URL.createObjectURL(file); // 本地预览
    this.showCropper = true;
  }

  async handleCropper() {
    this.$refs.cropper.getCropData(async res => {
      try {
        const formData = toFormData(res);
        this.loading = true;
        const { data } = await requestApp.post('/user/file/2', formData);
        this.form.avatar = data.data;
        this.showCropper = false;
      } catch (err) {
        this.$showToast(err.message);
        Promise.reject(new Error(err.message));
      } finally {
        this.loading = false;
      }
    });
  }

  get getContainerHeight() {
    const { pageHeight } = getBrowserInterfaceSize();
    return pageHeight;
  }
  get getContainerWidth() {
    const { pageWidth } = getBrowserInterfaceSize();
    return pageWidth;
  }
  get headerStyle() {
    if (this.getContainerHeight > this.getContainerWidth * 2) {
      return { maxHeight: this.getContainerWidth * 0.95 + 'px', paddingBottom: '14px' };
    }
    return { };
  }
}
</script>

<style lang="scss" scoped>
.page-login {
  width: 100%;
  height: 100vh;
  background-color: #f8f8d1;

  .contain {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    overflow: scroll;
    background-image: url("../../assets/auth-background.png");
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;

    .icon-back {
      position: absolute;
      top: 8px;
      width: 15px;
      height: 15px;
      padding: 12px;
    }

    .header {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 14px;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1.6px;
        color: #fff;
        gap: 10px;

        .name {
          padding: 0 16px;
        }

        .avatar {
          width: 78px;
          height: 78px;
          border: 1px solid #fff;
          border-radius: 50%;
          overflow: hidden;

          .image {
            width: 100%;
            height: 100%;
          }
        }
      }

      .introduction-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 33px;
        gap: 24px 22px;

        .item {
          width: 104px;
          height: 32px;
          border: 1px solid #faaf10;
          border-radius: 50px;
          font-size: 12px;
          line-height: 32px;
          text-align: center;
          white-space: nowrap;
          letter-spacing: 0.96px;
          color: #1e1e1e;
          background: #fff;
        }
      }
    }

    .content-wrapper {
      padding: 0 16px 16px;
      margin-top: 35px;

      @include media-xxs-only {
        padding: 6px;
      }

      .content-box {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0 12px 11px;
        border-radius: 12px;
        background-color: #fff;
        gap: 16px;

        .title {
          padding: 35px 0 19px;
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 33px;
          text-align: center;
          letter-spacing: 2.08px;
          color: #1e1e1e;

          .high-light {
            color: transparent;
            background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
            -webkit-background-clip: text; /* stylelint-disable-line */
          }
        }

        .form-box {
          display: flex;
          flex-direction: column;

          .field {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            height: 60px;
            border-bottom: 1px solid #f0f0f0;

            .label {
              width: 58px;
              padding-left: 4px;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              white-space: nowrap;
              letter-spacing: 0.32px;
              color: #8c8c8c;
            }

            .avatar-btn {
              box-sizing: border-box;
              width: 44px;
              height: 44px;
              margin-left: 11px;
              border: 1px solid #fff;
              border-radius: 50%;
              overflow: hidden;

              .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .icon-arrow {
              box-sizing: border-box;
              width: 6px;
              height: 6px;
              margin: 10px;
              border: 1px solid #8c8c8c;
              border-bottom: none;
              border-left: none;
              transform: rotate(45deg);
            }

            .input {
              flex: 1;
              min-width: 80px;
              height: 35px;
              margin-left: 11px;
              font-weight: 400;
              font-size: 16px;
              line-height: 35px;
              text-align: left;
              letter-spacing: 0.32px;
              color: $primary-color;
            }

            .send-verify {
              padding: 0 4px;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              white-space: nowrap;
              letter-spacing: 0.56px;
              color: #4c4c4c;
            }
          }
        }

        .click-box {
          display: flex;
          flex-direction: column;
          gap: 11px;

          .submit-btn {
            padding: 16px;
            border-radius: 50px;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            text-align: center;
            white-space: nowrap;
            letter-spacing: 0.72px;
            color: #fff;
            background: #4c4c4c;

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }
          }

          .checkbox-label {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            color: #8c8c8c;
            gap: 6px;

            strong {
              color: #000;
            }
          }
        }
      }
    }
  }

  .action-content {
    background: #fff;

    .action {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      padding-top: 12px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
      font-size: 16px;
      text-align: center;
      color: #1e1e1e;

      &:last-child {
        border-bottom: none;
      }

      .upload-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.4;
  }

  .cropper-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: $h5-max-width;
    color: #fff;
    background-color: #000;
    transition: display 0.3s;

    @include paddingBottomSafeArea(16px);

    .cropper-box {
      flex: 1;
      height: 90vw;
    }

    .btns {
      display: flex;

      .btn {
        flex: 1;
        padding: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        white-space: nowrap;
        letter-spacing: 0.72px;
      }
    }
  }
}
</style>
