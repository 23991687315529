import { requestApp } from '@/utils';
import { getUrlCode } from '@/utils/get-code';
import { SimpleStore } from './helper/simple-store';
import _ from 'lodash';

export class BusinessInfo extends SimpleStore {
  businessUserId = localStorage.getItem('businessId');
  businessUserTitle = sessionStorage.getItem('businessTitle');
  businessUserEmployeeId = sessionStorage.getItem('employeeId');
  storeQRCodeFlag = false; // 是否是扫码进入的店铺

  get shareQuery() {
    return _.pickBy({
      businessId: this.businessUserId,
      title: this.data.name || this.businessUserTitle,
      staffId: this.businessUserEmployeeId,
      storeQRCode: this.storeQRCodeFlag,
    });
  }

  init() {
    const businessId = getUrlCode('businessId');
    const businessTitle = decodeURIComponent(getUrlCode('title') || '');
    const employeeId = getUrlCode('staffId') || '';
    const storeQRCode = getUrlCode('storeQRCode') || sessionStorage.getItem('storeQRCode');
    if (businessId) {
      localStorage.setItem('businessId', businessId);
      this.businessUserId = businessId;
    }
    if (businessTitle) {
      sessionStorage.setItem('businessTitle', businessTitle);
      this.businessUserTitle = businessTitle;
    }
    if (employeeId) {
      sessionStorage.setItem('employeeId', employeeId);
      this.businessUserEmployeeId = employeeId;
    }
    if (storeQRCode) {
      sessionStorage.setItem('storeQRCode', storeQRCode);
      this.storeQRCodeFlag = true;
    }
    // 先保留着看输出
    console.log('business', this);
  }

  async fetchData() {
    const params = { businessUserId: this.businessUserId, employeeId: this.businessUserEmployeeId, storeQRCode: this.storeQRCodeFlag };
    await this.mergeFetched(requestApp.post('/homePage/h5BusinessInfo', params));
  }
}
export const businessInfo = new BusinessInfo();

export class BusinessIntroductionStore extends SimpleStore {
  async fetchData() {
    await this.mergeFetched(requestApp.post('/homePage/introduce', { businessUserId: businessInfo.businessUserId }));
  }
}
export const introductionStore = new BusinessIntroductionStore();

