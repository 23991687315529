<template>
  <div class="page">
    <PageLoading :onFetch="onFetch" />
    <div class="page-container">
      <NavBar />

      <div class="content">
        <div class="record">
          <InfiniteList v-if="imagesStore.data.length" :id="containerListId" :store="imagesStore" @more="waterfallUpdate">
            <Waterfall ref="waterfallRef" :options="{ gap:'0.58333rem', columns: 2 }">
              <RecordCard
                v-for="item in imagesStore.data"
                :key="item.id"
                :record="item"
                class="item"
              />
            </Waterfall>
          </InfiniteList>
          <EmptyView v-else />
        </div>
        <Support class="support" />
      </div>

      <TabBar @login="showLoginBox = true" />
    </div>

    <LoginBoxPopup :show="showLoginBox" @update:show="showLoginBox = $event" />
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import TabBar from '@/components/tabbar.vue';
  import { imagesStore, authStore } from '@/store';
  import PageLoading from '@/components/page-loading.vue';
  import InfiniteList from '@/components/infinite-list.vue';
  import RecordCard from './components/record-card.vue';
  import Waterfall from 'easy-waterfall-2/dist/waterfallVue';
  import EmptyView from '../components/empty-view.vue';
  import NavBar from '../components/navbar.vue';
  import LoginBoxPopup from '../home/components/login-box-popup.vue';
  import { omit } from 'lodash';
  import Support from '@/components/support.vue';

  @Component({
    components: {
      TabBar,
      PageLoading,
      InfiniteList,
      RecordCard,
      Waterfall,
      EmptyView,
      NavBar,
      LoginBoxPopup,
      Support,
    }
  })
  export default class Record extends Vue {
    imagesStore = imagesStore
    showLoginBox = false

    containerListId = 'container-list-id';

    async onFetch() {
      try {
        if (this.$route.query.code) {
          await authStore.login();
          this.$router.replace({ query: omit(this.$route.query, 'code') });
          if (!authStore.data.phone) {
            this.$router.push({ name: 'aiLogin', query: { name: 'aiRecord' } });
          } else {
            this.$showToast('登录成功');
          }
        }
        await Promise.all([
          authStore.tryFetchData(),
          imagesStore.fetchData(),
        ]);
        this.waterfallUpdate();
      } catch (err) {
        let errMsg = err.message;
        if ([400, 401, 20004, 20005].includes(Number(err.status))) { // 过期/token 认证失败
          errMsg = '登录已过期，请重新登录';
          this.showLoginBox = true;
        }
        this.$showToast(errMsg);
        Promise.reject(err);
      }
    }

    mounted() {
      this.wxShare({ shareParams: { link: `${location.origin}${this.$router.resolve({ name: 'aiRecord' }).href}` } });
    }

    waterfallUpdate() {
      if (this.$refs.waterfallRef) {
        this.$refs.waterfallRef.update();
      }
    }

    @Watch('imagesStore.data.length')
    async dataUpdate() {
      await this.$nextTick();
      this.waterfallUpdate();
    }
  }
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;

  > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8px 6px;
    overflow: auto;

    .record {
      flex: 1;
    }

    .support {
      margin-top: 36px;
    }
  }

  .item {
    padding-bottom: 17px;
  }
}
</style>
