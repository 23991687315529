import { SimpleStore } from './helper/simple-store';
import { requestUser, requestAi, getSrc } from '@/utils';
import { callbackUrl } from '@/constants/index';
import wx from 'weixin-js-sdk';
import { businessInfo } from '@/store';
import qs from 'qs';

class WxSdkStore extends SimpleStore {
  sdkData = {}
  aiInfo = {}
  encodeBusinessName = ''
  sdkConfigPromise = null;
  wxReady = false
  finishInit = false
  jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData', 'hideMenuItems', 'showMenuItems', 'previewImage']
  initUrl = window.location.href;

  get isIos() {
    return /iphone|ipad|ipod|ios/.test(navigator.userAgent.toLowerCase());
  }

  async fetchData() {
    const url = this.isIos ? this.initUrl : window.location.href;
    const { data } = await requestUser.get(`/h5/getJSSignature?url=${encodeURIComponent(url)}`);
    this.sdkData = data.data;
  }

  async getSdkConfig() {
    if (!this.sdkConfigPromise) {
      this.sdkConfigPromise = this.fetchData();
    }
    return this.sdkConfigPromise;
  }

  async init() {
    const [{ data }] = await Promise.all([
      requestAi.get('application/info'),
      businessInfo.tryFetchData(),
    ]);
    this.aiInfo.shareImgUrl = data.logo.url || data.share_image.url;
    this.aiInfo.shareDesc = data.share_desc || 'AI一键设计我的家';
    this.encodeBusinessName = encodeURIComponent(businessInfo.data.name);
    this.finishInit = true;
  }

  async setWxConfig() {
    if (!this.finishInit) {
      await this.init();
    }
    await this.getSdkConfig();
    return new Promise((resolve) => {
      wx.config({
        debug: false, //  调试模式
        appId: this.sdkData.appId, // 必填，公众号的唯一标识
        timestamp: Number(this.sdkData.timestamp), // 必填，生成签名的时间戳
        nonceStr: this.sdkData.noncestr, // 必填，生成签名的随机串
        signature: this.sdkData.signature, // 必填，签名
        jsApiList: this.jsApiList, // 必填，需要使用的JS接口列表
      });
      wx.ready(() => {
        resolve(true);
      });
    });
  }

  async share({ shareParams, canShare = true } = {}) {
    await this.setWxConfig();
    this.sdkConfigPromise = null;
    const linkQuery = {
      ...businessInfo.shareQuery,
      isShare: 1,
      title: this.encodeBusinessName || '',
    };
    const shareInfo = {
      link: `${this.originPath}?${qs.stringify(linkQuery)}`,
      imgUrl: this.isAiPage ? this.aiInfo.shareImgUrl : getSrc({ src: businessInfo.data.headPic }),
      desc: this.isAiPage ? this.aiInfo.shareDesc : `买东西，上${businessInfo.data.name || ''}`,
      success() {},
    };
    // 自定义分享链接，需要合并
    if (shareParams?.link) {
      const originQuery = shareParams.link.split('?')[1];
      Object.assign(linkQuery, qs.parse(originQuery));
      shareParams.link = `${shareParams.link.split('?')[0]}?${qs.stringify(linkQuery)}`;
    }
    const timelineShareInfo = { ...shareParams }; // 分享朋友圈参数
    Object.assign(timelineShareInfo, shareParams?.timelineTitle ? { title: shareParams.timelineTitle } : {});

    if (canShare) {
      wx.showMenuItems({
        menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:share:facebook', 'menuItem:share:QZone']
      });
      wx.updateAppMessageShareData({
        title: this.isAiPage ? businessInfo.data.name : '来我的店铺看看',
        ...shareInfo,
        ...shareParams,
      });
      wx.updateTimelineShareData({
        title: businessInfo.data.name,
        ...shareInfo,
        ...timelineShareInfo,
      });
    } else {
      wx.hideMenuItems({
        menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:share:facebook', 'menuItem:share:QZone']
      });
    }
  }

  get isAiPage() {
    return window.location.pathname.includes('/ai');
  }

  get originPath() {
    return this.isAiPage ? `${callbackUrl}/ai` : `${callbackUrl}`;
  }
}

export const wxSdkStore = new WxSdkStore();
