<template>
  <div style="width: 100%">
    <div class="cmpt-nav-bar" :style="customStyle" v-if="title === undefined || isShare">
      <div class="nav">
        <div class="text" @click="$router.push({ name: !title ? 'business-home' : 'aiHome' })">{{ !title ? '店铺主页' :
      'AI主页' }}</div>
      </div>
      <div class="ai-title" v-if="!isShare">
        <strong>AI</strong>智能设计
      </div>
    </div>
    <div v-else class="cmpt-nav-bar" :style="customStyle">
      <van-icon name="arrow-left" size="1.66667rem" v-if="showBack" class="back" @click="$router.go(-1)" />
      <div class="content" v-if="!isShare">
        <span class="title">{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NavBar extends Vue {
  @Prop({ type: String }) title
  @Prop({ type: String | Number }) isShare
  @Prop({ type: Object, default: () => ({}) }) customStyle

  get showBack() {
    return window.history.length > 1; // TODO: 判断不太准确
  }
}
</script>

<style lang="scss" scoped>
.cmpt-nav-bar {
  position: sticky;
  z-index: z('navbar', 'base');
  top: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: var(--navbar-height);
  padding: 11px 12px;
  color: #1e1e1e;
  background: #fff;

  .text {
    position: absolute;
    left: 0;
    box-sizing: border-box;
    width: 69px;
    height: 30px;
    border-radius: 0 50px 50px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.28px;
    color: #fff;
    background: linear-gradient(88deg, #fc9f09, #e5000e);
    transform: translateY(-50%);
  }

  .content {
    position: absolute;
    left: 50%;
    font-size: 0;
    transform: translateX(-50%);
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.8px;

    @include text-overflow();
  }

  .ai-title {
    flex: 1;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.4px;

    strong {
      color: transparent;
      background: linear-gradient(102deg, #e6070d 78.33%, #fb9d09 96.12%);
      -webkit-background-clip: text; /* stylelint-disable-line */
    }
  }
}
</style>
