<template>
  <div
    class="style-card"
    :class="{ checked: checked }"
  >
    <img :src="item.cover.url" class="image"/>

    <div class="text-wrapper text-overflow">
      {{ item.name }}
    </div>
    <img src="@/assets/select-border.png" class="select-border" v-show="checked">
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class StyleCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) item
    @Prop({ type: Boolean, default: () => false }) checked
  }
</script>

<style lang="scss" scoped>
.style-card {
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 107px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;

  .select-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .text-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 7px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: rgba($color: #000, $alpha: 0.3);
  }

  &.checked {
    border-radius: 0;  // select 圆角用图片实现的，防止设置和图片圆角不一致

    .text-wrapper {
      background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
      background-color: none;
    }
  }
}
</style>
