import Vue from 'vue';
export { decodeQuery, encodeQuery } from './query';

import { sleep } from './sleep';
import { setTitle } from './set-title';
import { isMobile } from './is-mobile';
Vue.prototype.$sleep = sleep;
Vue.prototype.$setTitle = setTitle;
Vue.prototype.$isMobile = isMobile;

export {
  sleep, setTitle
};

import './dialog';

export { decoder } from './decoder';
export { countdown } from './countdown';
export { randomString } from './random';
export { formVerification } from './form-verification';
export { Storage, ArrayStorage } from './storage';
export { setMeta } from './set-meta';
export { download } from './download';
export { getBrowserInterfaceSize } from './browser-interface-size';
export { requestUser, requestApp, requestAi } from './request';
export { uploadFile } from './upload-file';
export { isWXBrowser, getUrlCode, getWeChatCode } from './get-code';
export { autoLoading } from './dialog';
export { copy } from './copy';
export { saveFile } from './save-files';
export { getSrc } from './get-src';
export { toFormData, base64ToBlob } from './convert-base64';
