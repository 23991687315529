import { SimpleStore } from './helper/simple-store';

class SelectMore extends SimpleStore {
  data = [];

  updateData(data) {
    this.data = data;
  }

  clearData() {
    this.data = [];
  }
}

export const selectMoreStore = new SelectMore();
