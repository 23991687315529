import { render, staticRenderFns } from "./water-mask.vue?vue&type=template&id=24a9eb70&scoped=true"
import script from "./water-mask.vue?vue&type=script&lang=js"
export * from "./water-mask.vue?vue&type=script&lang=js"
import style0 from "./water-mask.vue?vue&type=style&index=0&id=24a9eb70&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_4rliqqz3ui7xo7iaw2ukiguuga/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a9eb70",
  null
  
)

export default component.exports