<template>
  <div class="search-result" ref="searchResultRef" @scroll="handleScroll">
    <InfiniteList :id="id" :store="store" @more="waterfallUpdate">
      <Waterfall ref="waterfallRef" :options="{ gap: '0.5rem', columns: 2 }">
        <WorkCard @imgLoad="waterfallUpdate" v-for="(item, index) in store.data" :key="index" :card="item" class="item" />
      </Waterfall>
    </InfiniteList>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import WorkCard from '@/components/work-card.vue';
import InfiniteList from '@/components/infinite-list.vue';
import Waterfall from 'easy-waterfall-2/dist/waterfallVue';
import { throttle } from 'lodash';

@Component({
  components: { WorkCard, InfiniteList, Waterfall },
})
export default class SearchResult extends Vue {
  @Prop({ type: Object, required: true, default: () => ({}) }) store

  id = 'searchResultListId'
  contentScrollTop = 0

  async mounted() {
    this.waterfallUpdate();
  }

  waterfallUpdate = throttle(() => {
    if (this.$refs.waterfallRef) {
      this.$refs.waterfallRef.update();
    }
  }, 100);

  @Watch('store.data.length')
  async dataUpdate() {
    await this.$nextTick();
    this.waterfallUpdate();
  }

  handleScroll() {
    this.contentScrollTop = this.$refs.searchResultRef.scrollTop;
  }
  async activated() {
    await this.$nextTick();
    this.$refs.waterfallRef.update();
    this.$refs.searchResultRef.scrollTop = this.contentScrollTop;
  }
}
</script>

<style lang="scss" scoped>
.search-result {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 6px;
  overflow-y: scroll;
}
</style>
