<template>
  <div class="progress-view">
    <div class="title">
      AI正在努力生成图片中
    </div>
    <Progressing class="gif"/>
    <div class="num">
      {{ value }}%
    </div>
    <div class="bar-box">
      <div class="bar total"></div>
      <div class="bar progressing" :style="{ width: `${value}%` }"></div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import Progressing from '@/components/progressing.vue';

  @Component({
    components: {
      Progressing
    }
  })
  export default class ProgressView extends Vue {
    @Prop({ type: [Number, null], default: 0 }) value;
  }
</script>

<style lang="scss" scoped>
.progress-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 47px;

  .title {
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.36px;
    color: transparent;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    -webkit-background-clip: text; /* stylelint-disable-line */
  }

  .gif {
    --progressing-size: 70px;
    margin-top: 31px;
  }

  .num {
    margin-top: 17px;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: 0.48px;
    color: transparent;
    background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    -webkit-background-clip: text; /* stylelint-disable-line */
  }
}

.bar-box {
  position: relative;
  width: 100%;
  margin-top: 17px;
  border-radius: 16px;
  overflow: hidden;

  .bar {
    height: 6px;
  }

  .total {
    background: rgba(250, 148, 9, 0.30);
  }

  .progressing {
    position: absolute;
    top: 0;
    left: 0;
    background: #fb9a09;
    transition: width 0.3s;
  }
}
</style>
