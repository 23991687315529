<template>
  <div class="page">
    <PageLoading :onFetch="onFetch" :forceLoading="isLoading" />
    <NavBar :title="(detail.id) ? ' ' : '生成效果图'" :isShare="isShare" />
    <div class="create-box" v-if="Boolean(isCreate) && !detail.id">
      <CreateLoading :code="code" style="--progress-padding-top: 2.75rem" @success="handleSuccess"
      @failed="$router.go(-1)" />
    </div>
    <div class="content" v-if="detail.id">
      <div>
        <div class="swiper-wrapper">
          <div class="title">{{ detail.title }}</div>
          <div class="relative">
            <van-swipe class="swiper" :show-indicators="false" :initial-swipe="current"
              :style="{ height: `${height}px` }" @change="(index) => current = index">
              <van-swipe-item v-for="item in detail.results" :key="item.id" class="swiper-item">
                <img class="swiper-image" :src="detail.origin.url" v-if="showOrigin" />
                <img class="swiper-image" :src="item.url" v-else />
                <div class="swiper-image water-mask">
                  <WaterMask :src="item.url" :text="`${businessUserTitle || ''}AI`" @init="isLoading = true" @finish="isLoading = false" />
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="comparison">
              <div class="comparison-btn black">AI 生成</div>
              <div class="comparison-btn" @touchstart="showOrigin = true" @touchcancel="showOrigin = false"
                @touchend="showOrigin = false" @mousedown="showOrigin = true" @mouseup="showOrigin = false">原图</div>
            </div>
          </div>
          <div class="indicator-dots">
            <span class="dot" :class="{ active: current === index }" v-for="(item, index) in 4" :key="item" @click="current = index"></span>
          </div>
        </div>
        <!-- 用户信息，通过分享进入可查看 -->
        <div class="user-info" v-if="isShare">
          <div class="flex-row justify-center items-center">
            <img :src="get(detail, 'user.avatar.url') || require('@/assets/default-avatar.png')" class="avatar">
            <div class="nickname">{{ get(detail, 'user.screen_name') || '微信用户' }}</div>
          </div>
          <div class="text">
            <div>我几秒设计了这个作品</div>
            <div>你也来试试！</div>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="operation flex justify-center" v-if="!isShare">
          <button style="width: 6.66667rem;" class="primary-btn gray" @click="handleImageToImage">以图改图</button>
          <button style="width: 6.66667rem;" class="primary-btn gray" @click="handleSave">保存</button>
        </div>
      </div>

      <div class="operation flex-col">
        <template v-if="isShare">
          <button class="primary-btn gray" @click="showShareSheet = true">分享</button>
          <button class="primary-btn gray" @touchstart="showOrigin = true" @touchcancel="showOrigin = false"
            @touchend="showOrigin = false">
            对比
          </button>
          <button class="primary-btn" style="font-weight: 500;" @click="handleGoHome">我也要设计</button>
        </template>
        <template v-else>
          <button class="primary-btn gray" @click="handleRestyle(detail.raw)">不满意，重新设计</button>
          <button class="primary-btn gray" @click="showShareSheet = true">分享</button>
        </template>
      </div>


      <Support v-if="isShare" class="support" />

      <ShareSheet v-model="showShareSheet" :showShareTips="showShareTips" :detail="detail"
        @update:showShareTips="showShareTips = $event" />

      <ShareTipsPopup v-model="showShareTips" />
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import CreateLoading from './components/create-loading.vue';
import PageLoading from '@/components/page-loading.vue';
import ShareSheet from './components/share-sheet.vue';
import ShareTipsPopup from '@/components/share-tips-popup.vue';
import { requestAi, randomString } from '@/utils';
import _, { get } from 'lodash';
import { resultGroup, businessInfo } from '@/store';
import NavBar from '../components/navbar.vue';
import WaterMask from './components/water-mask.vue';
import Support from '@/components/support.vue';

@Component({
  components: {
    PageLoading,
    CreateLoading,
    ShareSheet,
    NavBar,
    ShareTipsPopup,
    WaterMask,
    Support
  }
})
export default class AiDetail extends Vue {
  detail = {};
  current = 0;
  showOrigin = false;
  get = get;
  showShareSheet = false;
  showShareTips = false;
  businessUserTitle = businessInfo.businessUserTitle;
  isLoading = false;

  get code() {
    return this.$route.query.code;
  }

  get index() {
    return this.$route.query.index;
  }

  get isCreate() {
    return this.$route.query.isCreate;
  }

  get isShare() {
    return this.$route.query.isShare;
  }

  get height() {
    const { clientWidth } = document.documentElement;
    const { width = 0, height = 0 } = _.get(this.detail, 'raw.metadata', {});
    const res = height / width * (750 - 24);
    return clientWidth > 750 ? res : res / 2;
  }

  handleSuccess(data) {
    this.detail = data;
    this.wxShare({ shareParams: { desc: this.detail.title, link: this.url, title: '我用AI几秒设计了这个作品', imgUrl: this.detail.results[0].url } });
  }

  get url() {
    const query = {
      code: this.code,
      isShare: 1,
      businessId: businessInfo.businessUserId,
      title: businessInfo.businessUserTitle,
      staffId: businessInfo.businessUserEmployeeId,
      storeQRCode: businessInfo.storeQRCodeFlag
    };
    const href = this.$router.resolve({ name: 'aiResultDetail', query }).href;
    return `${location.origin}${href}`;
  }

  async onFetch() {
    if (this.isCreate) {
      return;
    }
    const { data } = await requestAi.get(`images/${this.code}`);
    this.detail = data;
    this.current = Number(this.index) || 0;
    this.wxShare({ shareParams: { desc: this.detail.title, link: this.url, title: '我用AI几秒设计了这个作品', imgUrl: this.detail.results[0].url } });
  }

  handleGoHome() {
    this.$router.push({ name: 'aiHome' });
  }

  handleImageToImage() {
    this.handleRestyle(this.detail.results[this.current]);
  }

  async handleSave() {
    this.$showToast('请长按图片保存');
  }

  @Vue.autoLoading
  async handleRestyle(origin) {
    const groupId = this.groupId || randomString();
    resultGroup.addResult(groupId, this.detail);
    const { data: { code } } = await requestAi.post('images', {
      origin: origin.signed_id,
      raw: this.detail.raw.signed_id,
      image_id: this.detail.id,
    });
    this.$router.push({ name: 'aiResultProgress', query: { code, groupId } });
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-bottom: Max(26px, env(safe-area-inset-bottom));

  .create-box {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  padding: 12px;
  overflow-y: scroll;

  .swiper-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 390px;

    .title {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.28px;
      color: #1e1e1e;
    }
  }

  .swiper-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  .comparison {
    position: absolute;
    bottom: 10px;
    left: 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;

    .comparison-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      min-width: 55px;
      height: 24px;
      border-radius: 20px;
      font-size: 12px;
      /* 100% */
      background: rgba(255, 255, 255, 0.8);
      -webkit-touch-callout:none; /* stylelint-disable-line */
      -webkit-user-select:none; /* stylelint-disable-line */
      -khtml-user-select:none; /* stylelint-disable-line */
      -moz-user-select:none; /* stylelint-disable-line */
      -ms-user-select:none; /* stylelint-disable-line */
      user-select:none; /* stylelint-disable-line */


      &.black {
        color: #fff;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .indicator-dots {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    .dot {
      width: 8px;
      height: 8px;
      margin: 0 2px;
      border-radius: 4px;
      background-color: #787878;
      opacity: 0.5;
      transition: width 0.2s;

      &.active {
        width: 16px;
      }
    }
  }

  .swiper-image {
    width: 100%;
    height: 100%;

    &.water-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1%;
    }
  }

  .user-info {
    margin-top: 30px;
    font-weight: 500;
    font-size: 14px;
    color: #1e1e1e;

    .avatar {
      width: 26px;
      height: 26px;
      margin-right: 6px;
      border-radius: 50%;
    }

    .text {
      margin-top: 11px;
      text-align: center;
    }
  }

  .operation {
    display: flex;
    margin-top: 30px;
    gap: 12px;

    .primary-btn {
      height: 36px;
      font-weight: 500;
      font-size: 14px;

      &.gray {
        color: #000;
        background: #f5f5f5;
      }
    }
  }
}

.support {
  margin-top: 36px;
}
</style>
