<template>
  <div class="tab-bar-wrapper flex-row items-center">
    <div class="flex-1">
    </div>
    <div class="tab-bar">
      <div class="link-box">
        <div v-for="tab in tabs" :key="tab.key" class="link" :class="{ active: modelValue === tab.key }"
          @click="handleTabChange(tab.key)">
          {{ tab.label }}
        </div>
      </div>
    </div>
    <div class="flex-1 icon-search-wrapper" @click="$emit('search-click')">
      <img src="@/assets/icon-search-black.png" alt="" class="icon-search" />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { businessInfo } from '@/store';

@Component({
  components: {
  },
})
export default class HomeTabBar extends Vue {
  @Prop({ type: Array, default: () => ([]) }) tabs;
  @Model('update:modelValue', { type: Number, default: 0 }) modelValue;

  businessInfo = businessInfo

  async mounted() {
    await businessInfo.tryFetchData();
  }
  handleTabChange(key) {
    this.$emit('update:modelValue', key);
  }
}
</script>

<style lang="scss" scoped>
.tab-bar-wrapper {
  padding: 0 18px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-color: #fff;
}

.icon-search-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  .icon-search {
    width: 20px;
    height: 20px;
  }
}

.tab-bar {
  display: flex;
  padding: 16px 0 12px;

  .link-box {
    display: inline-flex;
    margin: 0 auto;

    .link {
      position: relative;
      padding: 0 18px 6px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
      letter-spacing: 0.28px;
      color: #8c8c8c;

      &.active {
        color: $primary-color;
        transition: color 0.3s ease;

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50%;
          height: 2px;
          background-color: $primary-color;
          transform: translateX(50%);
          content: "";
        }
      }
    }
  }
}
</style>
