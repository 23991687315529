import { render, staticRenderFns } from "./introduction.vue?vue&type=template&id=5e039abd&scoped=true"
import script from "./introduction.vue?vue&type=script&lang=js"
export * from "./introduction.vue?vue&type=script&lang=js"
import style0 from "./introduction.vue?vue&type=style&index=0&id=5e039abd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_4rliqqz3ui7xo7iaw2ukiguuga/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e039abd",
  null
  
)

export default component.exports