<template>
  <div class="cmpt-search">
    <div class="search-quickly">
      <div class="title">
        <span>快速搜索</span>
      </div>
      <div class="content">
        <div v-for="(item, index) in data" :key="index" class="search-item" @click="$emit('search', item)">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SearchQuickly extends Vue {
  @Prop({ type: Array, default: [] }) data

  onHandleInput(e) {
    this.$emit('change', e.target.value);
    this.$emit('update:inputValue', e.target.value);
  }
}
</script>

<style lang="scss" scoped>
.cmpt-search {
  padding: 0 30px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.28px;
    color: #8c8c8c;
  }

  .content {
    margin-top: 8px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: $primary-color;

    .search-item {
      padding: 10px 0;
    }
  }
}
</style>
