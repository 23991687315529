<template>
  <div class="progress-page">
    <NavBar title=" " />
    <div class="page">
      <div class="group-wrapper">
        <div class="group" v-for="item in list" :key="item.id" @click="handleGoToDetail(item)">
          <div class="image-list">
            <div v-for="(result, index) in item.results" :key="result.id"
              @click.stop="() => handleGoToDetail(item, index)">
              <img :src="result.url" class="image" @load="handleImageLoad">
            </div>
          </div>
          <div class="text">{{ item.title }}</div>
        </div>
        <div class="group" v-if="newResult.id" @click="handleGoToDetail(newResult)">
          <div class="image-list">
            <div v-for="(result, index) in newResult.results" :key="result.id"
              @click.stop="() => handleGoToDetail(newResult, index)">
              <img :src="result.url" class="image" @load="handleImageLoad">
            </div>
          </div>
          <div class="text">{{ newResult.title }}</div>
        </div>
      </div>
      <!-- 加上 ID 用来定位页面滚动位置 -->
      <CreateLoading :id="createLoadingId" :code="newCode || code" @success="handleSuccess" />
      <button class="primary-btn gray" v-if="newResult.id" @click="handleRestyle">不满意，重新设计</button>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import CreateLoading from './components/create-loading.vue';
import { resultGroup } from '@/store';
import { requestAi } from '@/utils';
import _ from 'lodash';
import NavBar from '../components/navbar.vue';

@Component({
  components: {
    CreateLoading,
    NavBar
  }
})
export default class Progress extends Vue {
  get groupId() {
    return this.$route.query.groupId;
  }

  get code() {
    return this.$route.query.code;
  }

  repeatFlag = false;
  list = []
  newResult = {}
  newCode = ''
  createLoadingId = 'create-loading-id'

  mounted() {
    this.list = resultGroup.getResults(this.groupId);
    this.wxShare();
  }

  handleSuccess(data) {
    this.newResult = data;
  }

  handleGoToDetail(item, index = 0) {
    this.$router.push({ name: 'aiResultDetail', query: { code: item.code, groupId: this.groupId, index } });
  }

  handleImageLoad = _.debounce(() => {
    const element = document.querySelector(`#${this.createLoadingId}`);
    element && element.scrollIntoView({ behavior: 'smooth' });
  }, 200);

  @Vue.autoLoading
  async handleRestyle() {
    this.list = resultGroup.addResult(this.groupId, this.newResult);
    const body = {
      origin: this.newResult.origin.signed_id,
      raw: this.newResult.raw.signed_id,
      image_id: this.newResult.id,
    };
    this.newResult = {};
    const { data: { code } } = await requestAi.post('images', body);
    this.newCode = code;
  }
}
</script>


<style lang="scss" scoped>
.progress-page {
  height: 100vh;
  overflow: scroll;
}

.page {
  box-sizing: border-box;
  padding: 12px 12px env(safe-area-inset-bottom);

  .group-wrapper {
    .group {
      margin-bottom: 19px;

      .image-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 8px;
        border-radius: 8px;
        overflow: hidden;
        gap: 7px;

        .image {
          width: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }

      .text {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #1e1e1e;
      }
    }
  }

  .primary-btn.gray {
    margin: 30px 0 26px;
    font-weight: normal;
    font-size: 14px;
    color: #000;
    background: #f5f5f5;
  }
}
</style>
