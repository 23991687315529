<template>
  <div class="page">
    <v-title :value="businessUserTitle" />
    <PageLoading :onFetch="onFetch" />

    <div class="page-container">
      <div class="content">
        <InfiniteList v-if="collectionsStore.data?.list?.length" :id="id" :store="collectionsStore" @more="waterfallUpdate" class="flex-1">
          <Waterfall ref="waterfallRef" :options="{ gap: '0.5rem', columns: 2 }">
            <WorkCard @imgLoad="waterfallUpdate" v-for="(item, index) in collectionsStore.data?.list" :key="index" :card="item" show-collected class="item" />
          </Waterfall>
        </InfiniteList>
        <div v-else class="empty">
          <img src="@/assets/empty.svg" alt="" class="image" />
          <div class="title">收藏夹是空的，快去收藏吧</div>
        </div>
      </div>
      <PageTabBar page="business" />
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import PageTabBar from '@/components/tabbar.vue';
import PageLoading from '@/components/page-loading.vue';
import { businessUserTitle, collectionsStore } from '@/store';
import WorkCard from '@/components/work-card.vue';
import InfiniteList from '@/components/infinite-list.vue';
import Waterfall from 'easy-waterfall-2/dist/waterfallVue';
import { throttle } from 'lodash';
import EmptyView from '@/views/ai/components/empty-view.vue';

@Component({
  name: 'Collection',
  components: { PageTabBar, PageLoading, WorkCard, InfiniteList, Waterfall, EmptyView },
})
export default class Collection extends Vue {
  businessUserTitle = businessUserTitle
  collectionsStore = collectionsStore

  id = 'CollectionWorkCardId'

  waterfallUpdate = throttle(() => {
    if (this.$refs.waterfallRef) {
      this.$refs.waterfallRef.update();
    }
  }, 100);

  async onFetch() {
    await collectionsStore.fetchData();
    this.wxShare();
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 12px 7px;
    overflow: auto;
    gap: 24px;
    background: #fafafa;
  }

  :deep(.empty) {
    .title,
    .subtitle {
      color: #000;
      background-image: none;
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 100%;

    .image {
      width: 181px;
      height: 181px;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.2px;
      color: #424242;
    }
  }
}
</style>
