<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AiLayout extends Vue {
  includeComponents=['AiHome', 'SelectMore']
}
</script>

<template>
  <!-- 先都keepalive，后续有需求再调整 -->
  <keep-alive :include="includeComponents">
    <router-view></router-view>
  </keep-alive>
</template>
