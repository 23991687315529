import Vue, { ref } from 'vue';
import VueRouter from 'vue-router';
import { decodeQuery, encodeQuery } from '@/utils';
import { businessInfo } from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'business',
    component: require('@/views/business/layout').default,
    children: [
      {
        path: '',
        name: 'business-home',
        component: require('@/views/business/home/index').default,
      },
      {
        path: '/search',
        name: 'search',
        component: require('@/views/business/search/index').default,
      },
      {
        path: '/store-environment',
        name: 'store-environment',
        component: require('@/views/business/store-environment').default,
      },
      {
        path: '/video-detail',
        name: 'video-detail',
        component: require('@/views/business/detail/video-detail').default,
      },
      {
        path: '/label-detail',
        name: 'label-detail',
        component: require('@/views/business/detail/label-detail').default,
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: require('@/views/business/agreement').default
      },
      {
        path: '/authorization',
        name: 'authorization',
        component: require('@/views/business/authorization').default
      },
      {
        path: '/tech-support',
        name: 'tech-support',
        component: require('@/views/business/tech-support').default
      },
      {
        path: '/albums/:id',
        name: 'albums',
        component: require('@/views/business/albums').default
      },
      {
        path: 'collection',
        name: 'collection',
        component: require('@/views/business/collection/index').default
      }
    ]
  },
  {
    path: '/ai',
    name: 'ai',
    component: require('@/views/ai/layout').default,
    children: [
      {
        path: '',
        name: 'aiHome',
        component: require('@/views/ai/home/index').default,
      },
      {
        path: 'select_more',
        name: 'aiSelectMore',
        component: require('@/views/ai/select-more/index').default,
      },
      {
        path: 'detail',
        name: 'aiResultDetail',
        component: require('@/views/ai/result/detail').default
      },
      {
        path: 'progress',
        name: 'aiResultProgress',
        component: require('@/views/ai/result/progress').default
      },
      {
        path: 'record',
        name: 'aiRecord',
        component: require('@/views/ai/record').default
      },
      {
        path: 'share_poster',
        name: 'aiSharePoster',
        component: require('@/views/ai/result/share-poster').default
      },
      {
        path: 'login',
        name: 'aiLogin',
        component: require('@/views/ai/login').default
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: require('@/views/404').default
  },
  {
    path: '*',
    redirect: '/404'
  }
].filter(Boolean);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes,
  parseQuery(query) {
    return decodeQuery(query);
  },
  stringifyQuery(query) {
    return encodeQuery(query);
  }
});

export const previousRoute = ref(null);

router.beforeEach((to, from, next) => {
  previousRoute.value = from;

  // 避免businessId丢失
  if (!to.query.businessId) {
    next({
      path: to.path,
      query: {
        ...to.query,
        businessId: businessInfo.businessUserId,
      },
    });
  } else {
    next();
  }
});

export default router;
