import { SimpleStore } from './helper/simple-store';
import { requestAi } from '@/utils';

class Options extends SimpleStore {
  async fetchData() {
    const [
      { data: style },
      { data: space },
      { data: material },
      { data: light },
      { data: color },
    ] = await Promise.all([
      requestAi.get('options', { params: { page: 0, type_eq: 'Options::Style' } }),
      requestAi.get('options', { params: { page: 0, type_eq: 'Options::Space' } }),
      requestAi.get('options', { params: { page: 0, type_eq: 'Options::Material' } }),
      requestAi.get('options', { params: { page: 0, type_eq: 'Options::Light' } }),
      requestAi.get('options', { params: { page: 0, type_eq: 'Options::Color' } }),
    ]);

    this.mergeFetched({
      style,
      space,
      material,
      light,
      color,
    });
  }

  async getCustomOptions() {
    const { data: custom } = await requestAi.get('options', { params: { page: 0, type_eq: 'Options::Custom' } });
    this.mergeFetched({ custom });
  }

  async addCustomOption(str) {
    const { data } = await requestAi.post('options', { name: str });
    this.custom.unshift(data); // 更新custom列表
  }

  async deleteCustomOption(id) {
    await requestAi.delete(`options/${id}`);
    this.custom = this.custom.filter((item) => item.id !== id);
  }
}

export const options = new Options({
  style: [],
  space: [],
  material: [],
  light: [],
  color: [],
  custom: [],
});
