<template>
  <div class="cmpt-store-card">
    <div class="info">
      <div class="left">
        <img :src="getSrc({ src: info.headPic, suffix: '?x-oss-process=image/resize,w_100,m_lfit/format,jpg' })" alt=""
          class="store-image" />
      </div>
      <div class="right">
        <div class="title">
          {{ info.name || '好看' }}
        </div>
        <div class="info">
          <div class="text">
            浏览 {{ getCount(info.browse) }}
          </div>
          <div class="text">
            灵感 {{ getCount(info.count) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="info.introduction" class="intro">{{ info.introduction }}</div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getSrc } from '@/utils';

@Component
export default class StoreCard extends Vue {
  @Prop({ type: Object, default: () => ({}) }) info;

  getSrc = getSrc

  getCount(count) {
    if (!count) {
      return 0;
    }
    const number = Number(count);
    if (number > 10000) {
      return (number / 10000).toFixed(1) + '万';
    }
    return number.toString();
  }
}
</script>

<style lang="scss" scoped>
.cmpt-store-card {
  box-sizing: border-box;
  padding: 12px 10px;
  border-radius: 10px;
  background: $white;
  box-shadow: 0 1px 6px 0px rgba(136, 136, 136, 0.14);

  .info {
    display: flex;
    gap: 10px;
    align-items: center;

    .left {
      width: 48px;
      height: 48px;
      border: 1px solid $black;
      border-radius: 50%;
      overflow: hidden;

      .store-image {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #595959;
      gap: 14px;

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        word-break: break-all;
        letter-spacing: 0.32px;
        color: $primary-color;
      }

      .info {
        display: flex;
        gap: 16px;

        .text {
          position: relative;

          &:not(:last-child)::after {
            position: absolute;
            top: 50%;
            right: -8px;
            display: block;
            width: 1px;
            height: 12px;
            background-color: #595959;
            transform: translateY(-50%);
            content: "";
          }
        }
      }
    }
  }

  .intro {
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    line-break: anywhere;
    letter-spacing: 0.24px;
    color: #595959;
  }
}
</style>
