import { requestApp } from '@/utils';
import { SimpleStore } from './helper/simple-store';

class Agreements extends SimpleStore {
  async fetchData(params) {
    const res = await requestApp.post('/user/agreement', params);
    this.mergeFetched(res.data);
  }
}

export const agreements = new Agreements();
