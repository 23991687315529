<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { setMeta } from '@/utils';

  @Component
  class Title extends Vue {
    @Prop(String) value;

    created() {
      this.listener = this.$watch(
        () => this.value,
        title => {
          if (title) {
            this.$setTitle(title);
            setMeta('og:title', title, 'property');
          }
        },
        { immediate: true }
      );
    }

    beforeDestroy() {
      this.listener();
    }

    render() {
      return null;
    }
  }

  export default {
    install: Vue => Vue.component('v-title', Title)
  };
</script>
