import { requestApp } from '@/utils/request';
import { Collection } from './helper/collection';
import { businessInfo } from './business';

export class RelationStore extends Collection {
  async fetch(params) {
    return await requestApp.get(`/search/relation/${encodeURIComponent(params.text)}?businessUserId=${businessInfo.businessUserId}`);
  }
}
export const relationStore = new RelationStore();


export class SearchStore extends Collection {
  async fetch(params) {
    return await requestApp.get(`/search/${encodeURIComponent(params.text)}/${this.params.page}/${this.params.pageSize}?businessUserId=${businessInfo.businessUserId}`);
  }
}
export const searchStore = new SearchStore();

