import { requestAi } from './request';
import { randomFileName } from './random';
import { createChecksum } from './file-checksum';

export async function uploadFile(file) {
  const body = {
    filename: file.name || randomFileName(''),
    content_type: file.type || 'application/octet-stream',
    byte_size: file.size,
    hex_digest: await createChecksum(file),
  };

  const { data } = await requestAi.post('active_storage/direct_upload', body);
  await requestAi.put(data.direct_upload.url, file.slice(), { headers: data.direct_upload.headers, timeout: 1 * 60 * 60 * 1000 });

  return {
    // blob_url 用于渲染
    url: data.direct_upload.blob_url,
    signed_id: data.signed_id,
  };
}
