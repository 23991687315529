<template>
  <div
    class="tag"
    :class="{ checked: checked, column: !$slots.icon }"
    @click="$emit('tag-click')"
  >
    <div class="text text-overflow">
      {{ tag.name }}
    </div>
    <div v-if="$slots.icon" class="icon-wrapper">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Tag extends Vue {
    @Prop({ type: Object, default: () => ({}) }) tag
    @Prop({ type: Boolean, default: () => false }) checked
  }
</script>

<style lang="scss" scoped>
.tag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 7px;
  border: 2PX solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  background-color: #f8f8f8;

  &.column {
    flex-direction: column;
  }

  &.checked {
    background-image: linear-gradient(98deg, rgba(229, 0, 14, 0.12) 0%, rgba(252, 159, 9, 0.12) 100%);

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: -2px;
      border-radius: inherit;
      background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
      content: '';
    }

    .text {
      color: transparent;
      background-image: linear-gradient(91deg, #e5000e 0%, #fc9f09 100%);
    }
  }

  .text {
    flex: 1;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #1e1e1e;
    -webkit-background-clip: text; /* stylelint-disable-line */
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    width: 8px;
    height: 100%;

    img {
      width: 8px;
      height: 8px;
      margin-top: -3px;
    }
  }
}
</style>
